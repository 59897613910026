import { mapGetters } from 'vuex';
import offerConstants from '@core/utils/constants/offer';


export default {
    computed: {
        ...mapGetters( {
            packageOfferType: 'cart/packageOfferType',
        } ),
        hasCartPackageOfferPreBundle( ) {
            return this.packageOfferType === offerConstants.TYPES.PRE_BUNDLE_PACKAGE;
        },
        hasCartPackageOfferExperience( ) {
            return this.packageOfferType === offerConstants.TYPES.EXPERIENCES_PACKAGE;
        },
    }
};
