import Vue from 'vue';
import {
    required,
    requiredIf,
    email,
    numeric,
    minLength,
    maxLength,
    sameAs,
} from 'vuelidate/lib/validators';
import {
    zipCA as validateUtilsZipCA,
} from '@core/utils/validate';
import {
    sort as shippingOptionsUtilsSort,
    findOneElectronicTicketItem as shippingOptionsUtilsFindOneElectronicTicketItem
} from '@core/utils/shippingOptionsUtils';


import VuelidateMessages from '@core/mixins/forms/VuelidateMessages';

const ZIP_MIN_LENGTH_US = 5;

export default {
    mixins: [ VuelidateMessages ],
    data( ) {
        return {
            customerModel: {
                firstName: '',
                lastName: '',
                address: '',
                city: '',
                zip: '',
                state: '',
                country: 'CA',
                phone: '',
                email: '',
                confirmEmail: '',
                hotelReservationRequest: ''
            },
            shippingModel: {
                loading: true,
                optionId: '',
                options: [ ],
                lastSubmittedOptionId: null,
                globalElectronicShippingAvailable: false,
            },
            agreeModel: {
                termsEventTicket: false,
                termsPrivacyPolicy: false,
                termsEventHealthCheck: true
            },
            required: {
                state: true
            }
        };
    },
    validations() {
        const ret = {
            customerModel: {
                firstName: {
                    required,
                    maxLength: maxLength( 30 )
                },
                lastName: {
                    required,
                    maxLength: maxLength( 30 )
                },
                address: {
                    required,
                    maxLength: maxLength( 100 )
                },
                city: {
                    required,
                    maxLength: maxLength( 100 )
                },
                zip: {
                    required,

                },
                state: {
                    requiredIf: requiredIf( function( ) {
                        return this.required.state;
                    } )
                },
                country: {
                    required,
                    requiredShippingOptions: function( ) {
                        return this.hasShippingOptions;
                    }
                },
                phone: {
                    required
                },
                email: {
                    required,
                    maxLength: maxLength( 255 ),
                    email
                },
                confirmEmail: {
                    required,
                    sameAs: sameAs( ( ) => this.customerModel.email )
                },
                hotelReservationRequest: {
                    maxLength: maxLength( 500 )
                }
            },
            shippingModel : {
                optionId: {
                    requiredShippingOptions: function ( ) {
                        return !!( this.hasShippingOptions && this.shippingModel.optionId );
                    }
                }
            },
            agreeModel: {
                checkedAll: function () {
                    return Object.values( this.agreeModel ).every( term => term );
                }
            },
        };

        if ( this.customerModel && this.customerModel.country === 'US' ) {
            const zipValidationsUS = {
                numeric,
                minLength: minLength( ZIP_MIN_LENGTH_US )
            };
            Object.assign( ret.customerModel, { zip: zipValidationsUS } );
        }

        if ( this.customerModel.country && this.customerModel.country === 'CA' ){
            const zipValidationsCA = {
                required,
                minLength: minLength( 6 ),
                zip: validateUtilsZipCA
            };
            Object.assign( ret.customerModel, { zip: zipValidationsCA } );
        }

        if ( !this.hasShipping ) {
            delete ret.shippingModel;
            delete ret.customerModel.country.requiredShippingOptions;
        }

        return ret;
    },
    computed: {
        _validationsMessages( ) {
            return {
                customerModel: {
                    firstName: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', {  field: this.$t( '_components.fieldsets.detailFieldset.labels.firstName' ) } ),
                        maxLength: ( field, params ) => this.$t( '_common:formErrors.maxLength', { length: params.max } )
                    },
                    lastName: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.detailFieldset.labels.lastName' ) } ),
                        maxLength: ( field, params ) => this.$t( '_common:formErrors.maxLength', { length: params.max } )
                    },
                    address: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.paymentFieldset.labels.address' ) } ),
                        maxLength: ( field, params ) => this.$t( '_common:formErrors.maxLength', { length: params.max } )
                    },
                    city: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.paymentFieldset.labels.city' ) } ),
                        maxLength: ( field, params ) => this.$t( '_common:formErrors.maxLength', { length: params.max } ),
                    },
                    zip: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.paymentFieldset.labels.zip' ), interpolation : { escapeValue: false } } ),
                        minLength: ( field, params ) => this.$t( '_common:formErrors.minLength', { length: params.min } ),
                        zip:( ) => this.$t( '_common:formErrors.zip' )
                    },
                    state: {
                        requiredIf: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.paymentFieldset.labels.state' ), interpolation : { escapeValue: false } } ),
                    },
                    country: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.paymentFieldset.labels.country' ) } ),
                        requiredShippingOptions: ( ) => this.$t( '_components.fieldsets.paymentFieldset.errors.noShippingOptionsForCountry' )
                    },
                    phone: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.paymentFieldset.labels.phone' ) } )
                    },
                    email: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.detailFieldset.labels.email' ) } ),
                        maxLength: ( field, params ) => this.$t( '_common:formErrors.maxLength', { length: params.max } ),
                        email: ( field, params ) => this.$t( '_common:formErrors.email', { length: params.max } )
                    },
                    confirmEmail: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( '_components.fieldsets.detailFieldset.labels.emailConfirm' ) } ),
                        sameAs: ( ) => this.$t( '_common:formErrors.matchFields', { fields: this.$t( '_common:terms.email_plural' ) } ),
                    },
                    hotelReservationRequest: {
                        maxLength: ( field, params ) => this.$t( '_common:formErrors.maxLength', { length: params.max } ),
                    },
                },
                shippingModel: {
                    optionId: {
                        requiredShippingOptions: ( ) => this.$t( '_components.fieldsets.shippingFieldset.errors.requireShippingOptions' )
                    }
                },
                agreeModel: {
                    checkedAll: ( ) => this.$t( '_components.fieldsets.action.errors.requireTerms' )
                },
            };
        },
        hasShippingOptions() {
            return !!this.shippingModel.options.length;
        },
        cartShippingOptionId( ) {
            return this.ticket ? this.ticket.shippingId : null;
        },
    },
    methods: {
        /**
         * Load shipping options for a country
         *
         * first we will sort the countries.
         * if we have an shipping option on the ticket and that is on the response we will select that
         * If the first option is an electronic shipping options only show that
         * If we have one option select that
         */
        async loadShippingOptionsForCountry( ) {
            this.shippingModel.loading = true;
            this.shippingModel.optionId = null;

            const items = await this.getShippingOptions( { id: this.cart.id, data: { countryCode: this.customerModel.country } } );
            this.shippingModel.options = shippingOptionsUtilsSort ( items || [ ] );
            this.shippingModel.optionId = null;
            const isCartShippingOptionInList = !!this.shippingModel.options.find( value => value.id === this.cartShippingOptionId );
            if ( this.cartShippingOptionId && isCartShippingOptionInList ) {
                this.shippingModel.optionId = this.cartShippingOptionId;
            } else {
                const firstElectronicOption = shippingOptionsUtilsFindOneElectronicTicketItem( this.shippingModel.options );
                if ( firstElectronicOption ) {
                    this.shippingModel.options = [ firstElectronicOption ];
                }
                if ( this.shippingModel.options.length === 1 ) {
                    this.shippingModel.optionId = this.shippingModel.options[ 0 ].id;
                }
            }

            this.shippingModel.loading = false;
        },
        clearShippingOptions( ) {
            this.shippingModel.options = [ ];
        },

        updateFormModelAfterLoadingCart( ) {
            if ( !this.eventHealthCheck ) {
                Vue.delete( this.agreeModel, 'termsEventHealthCheck' );
            }
        },
        onChangeRequiredFieldDo( { field, value } ) {
            this.$set( this.required, field, value );
        }
    }
};
