<template>
    <div class="gst-hotel-reservation d-md-block" data-test-id="hotel-reservation">
        <div class="gst-hotel-reservation__details d-flex flex-row flex-md-column justify-md-center">
            <div class="gst-hotel-reservation__details__banner u-position-relative d-flex flex-column justify-center d-md-block">
                <BaseImage
                    :src="hotelImageSrc.src"
                    :fallback-src="hotelImageSrc.fallBack"
                    class="gst-hotel-reservation__image"
                    :cover="$vuetify.breakpoint.mdAndUp"
                    :container="!$vuetify.breakpoint.mdAndUp" />
            </div>
            <div class="gst-hotel-reservation__details__text">
                <h5>{{ hotel.name }}</h5>
                <div v-if="showGiftCard" class="d-flex align-center">
                    <GiftCardChip :amount="giftCard.amount" :currency="giftCard.currency" />
                    <BaseTooltip
                        :text="$t('giftCardTooltip')"
                        :open-on-click="!$vuetify.breakpoint.mdAndUp"
                        :open-on-hover="$vuetify.breakpoint.mdAndUp"
                        class-content="gst-hotel-reservation__details__tooltip"
                        class-activator="gst-hotel-reservation__details__tooltip-activator ml-4 ml-md-2">
                        <BaseIcon symbol-id="icons--help" class="gst-hotel-reservation__details__icon" :width="18" :height="18" />
                    </BaseTooltip>
                </div>
                <p>{{ locationFormatted }}</p>
                <p>{{ totalStayText }}</p>
                <p>{{ accommodation.roomsCount }} x {{ room.name }}</p>
            </div>
        </div>
        <div class="gst-hotel-reservation__dates d-flex flex-row">
            <div>
                <span>{{ $t("checkIn") }}</span>
                <span class="date-bold">{{ checkInDateText }}</span>
                <span v-if="checkInTime">{{ checkInTime }}</span>
            </div>
            <div>
                <span>{{ $t("checkOut") }}</span>
                <span class="date-bold">{{ checkOutDateText }}</span>
                <span v-if="checkOutTime">{{ checkOutTime }}</span>
            </div>
        </div>
        <div class="gst-hotel-reservation__services mb-2">
            <div v-if="roomRate.mealPlan">
                <BaseIcon :width="18" :height="18" symbol-id="icons--check_round" /> {{ roomRate.mealPlan | mealPlan }}
            </div>
        </div>
        <div v-if="showCancellationPolicies" class="gst-hotel-reservation__cancellation-policies">
            <CancellationPolicies :data="roomRate.cancellationPolicies" :currency="currency" />
        </div>
        <button v-if="roomRate.additionalNotes" class="gst-hotel-reservation__additional-notes-btn" @click="openAdditionalNotesModal()">
            {{ $t('buttons.additionalNotes') }}
        </button>
    </div>
</template>

<script>
    import dateConstants from '@core/utils/constants/date';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import GiftCardChip from '@tenant/app/components/chips/GiftCardChip';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';
    import { getImageByWidth, getImageModel } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import AdditionalNotesModal from '@tenants/ticketmaster/app/components/modals/AdditionalNotesModal.vue';
    import { getPrice } from '@tenant/app/utils/hotelReservationCancellationPolicies';
    import CartPurchaseFlowMixin from '@tenant/app/mixins/CartPurchaseFlow';
    import CartPackageOfferMixin from '@tenant/app/mixins/CartPackageOfferMixin';
    import CancellationPolicies from '@tenant/app/modules/hotel/components/cards/RoomCardVariant2/CancellationPolicies.vue';

    export default {
        name: 'HotelReservationContainer',
        components: {
            BaseImage,
            CancellationPolicies,
            BaseTooltip,
            BaseIcon,
            GiftCardChip
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckout._components.containers.hotelReservationContainer'
        },
        mixins: [ CartPurchaseFlowMixin, CartPackageOfferMixin ],
        props: {
            accommodation: {
                type: Object,
                required: true
            },
            hotel: {
                type: Object,
                required: true
            },
            room: {
                type: Object,
                required: true
            },
            currency: {
                type: String,
                required: true
            },
            isPackageOffer: {
                type: Boolean,
                default: false,
            },
            hasHotelReservationOnly: {
                type: Boolean,
                default: false
            },
            giftCard: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            totalStayText( ) {
                const stayText = `${this.$t( 'stayLength' )}`;
                const nightWithCount =  this.$t( '_common:terms.nightWithCount', { count: this.accommodation.nights } );
                return `${stayText} ${nightWithCount}`;
            },
            content () {
                return this.roomRate.additionalNotes;
            },
            roomRate () {
                return this.room.rate;
            },
            showCancellationPolicies( ) {
                return !this.isCartExperiencesFlow && !this.isCartPackagesFlow && this.roomRate?.cancellationPolicies?.length;
            },
            cancellationCost( ) {
                if ( this.isCartOpenInventoryFlow && this.roomRate.cancellationPolicies.length ) {
                    const sortedPolicies = [ ...this.roomRate.cancellationPolicies ].sort( ( a, b ) => new Date( a.fromAt ) - new Date( b.fromAt ) );
                    const costs = sortedPolicies.map( policy => {
                        return {
                            date: this.$options.filters.date( policy.fromAt, dateConstants.FORMAT.UI_DATE_TIME ),
                            amount: this.$options.filters.currencyFilter( getPrice( policy, this.hotel.roomRate, this.accommodation.nights ), this.currency )
                        };
                    } );
                    costs.unshift( {
                        date: this.$options.filters.date( sortedPolicies[0].fromAt, dateConstants.FORMAT.UI_DATE_TIME ),
                        amount: this.$options.filters.currencyFilter( 0, this.currency )
                    } );
                    return costs;
                }
                return null;
            },
            checkInTime () {
                const { checkInDateTime } = this.hotel.policy;

                if ( !checkInDateTime ) {
                    return;
                }
                return `${this.$t( '_common:terms.from' )} ${this.$options.filters.date( checkInDateTime, 'p' ) }`;
            },
            checkOutTime () {
                const { checkOutDateTime } = this.hotel.policy;

                if ( !checkOutDateTime ) {
                    return;
                }
                return `${this.$t( '_common:terms.until' )} ${this.$options.filters.date( checkOutDateTime, 'p' ) }`;
            },
            checkInDateText ( ) {
                const { startDateTime } = this.accommodation;

                return this.$options.filters.date( startDateTime, 'iiii • MMM dd, yyyy' );
            },
            checkOutDateText ( ) {
                const { endDateTime } = this.accommodation;

                return this.$options.filters.date( endDateTime, 'iiii • MMM dd, yyyy' );
            },
            locationFormatted() {
                const { address } = this.hotel;

                return `${address.address},
                        ${address.cityName},
                        ${address.stateCode || ''},
                        ${address.zipCode || ''}`;
            },
            hotelImageSrc( ) {
                if ( this.hasCartPackageOfferPreBundle ) {
                    return {
                        src: getImageByWidth( IMAGE_SIZE.LARGE, getImageModel( this.hotel.packageOffer.imageUrl ) ),
                        fallBack: getImageByWidth( IMAGE_SIZE.ORIGINAL, getImageModel( this.hotel.packageOffer.imageUrl ) )
                    };
                }
                return {
                    src: getImageByWidth( IMAGE_SIZE.LARGE, this.hotel.image ),
                    fallBack: getImageByWidth( IMAGE_SIZE.ORIGINAL, this.hotel.image )
                };
            },
            showGiftCard( ) {
                return this.giftCard && this.giftCard.amount;
            }
        },
        methods: {

            openAdditionalNotesModal() {
                const modalConfigDesktop = {
                    'no-click-animation': true,
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    'max-width': 600,
                    'content-class': 'gst-v-dialog',
                };

                const modalConfigMobile = {
                    'content-class': 'gst-v-dialog',
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition'
                };
                if ( this.$vuetify.breakpoint.mdAndUp ) {
                    this.$modal.show(
                        AdditionalNotesModal,
                        { content: this.content },
                        modalConfigDesktop
                    );
                } else {
                    this.$modal.showBottom(
                        AdditionalNotesModal,
                        { content: this.content },
                        modalConfigMobile
                    );
                }
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-hotel-reservation {
    @include border-radius('xs');

    padding: theme-spacing( 4 ) theme-spacing( 2 );
    border: 1px solid theme-color( 'septenary' ) !important;

    .gst-hotel-reservation__details__tooltip-activator {
        display: inline-block;
        vertical-align: text-top;

        .gst-svg-icon {
            stroke: theme-color( 'primary' ) !important;
        }
    }

    .gst-hotel-reservation__details__tooltip-activator:hover {
        .gst-svg-icon {
            stroke: theme-color( 'primary' ) !important;
        }
    }

    .gst-hotel-reservation__details__banner {
        margin: theme-spacing( 'n4' ) theme-spacing( 'n2' ) 0;
        flex: 1;

        .gst-hotel-reservation__image {
            max-height: 215px;
        }

        .fallback-image {
            height: 215px;
        }
    }

    .gst-hotel-reservation__additional-notes-btn {
        margin-top: theme-spacing( 4 );
        color: theme-color( 'primary' );
    }

    .gst-hotel-reservation__details {
        h5 {
            line-height: line-height( 'xxxl' );
            margin-top: theme-spacing( 4 );
            margin-bottom: theme-spacing( 2 );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'large' );
        }

        p {
            line-height: line-height( 'xl' );
            margin-bottom: theme-spacing( 1 );
            color: theme-color( 'tertiary' );
            font-size: font-size( 's' );
            font-weight: font-weight( 'regular' );
        }
    }

    .gst-hotel-reservation__dates {
        margin-top: theme-spacing( 6 );

        > div {
            flex: 1;

            span {
                display: block;
                width: 100%;
                margin-bottom: theme-spacing( 1 );
                color: theme-color( 'tertiary' );
            }

            .date-bold {
                color: theme-color( 'quaternary' );
                font-weight: font-weight( 'bold' );
            }
        }
    }

    .gst-hotel-reservation__services {
        margin-top: theme-spacing( 4 );

        > div {
            display: flex;
            font-size: font-size( 'xxs' );
            align-items: center;

            svg {
                margin-right: theme-spacing( 2 );

                .gst-svg-icon {
                    fill: theme-color( 'success' );
                }
            }
        }
    }

    .gst-hotel-reservation__details__icon {
        path {
            stroke: theme-color( 'tertiary' );
        }
    }

    .gst-hotel-reservation__details__icon:hover {
        path {
            stroke: theme-color( 'primary' );
        }
    }

    @include mobile-only {
        padding: theme-spacing( 4 ) theme-spacing( 2 );

        .gst-hotel-reservation__details {
            .gst-hotel-reservation__details__banner {
                margin: 0 theme-spacing( 4 ) 0 0;
                max-width: 30%;

                .fallback-image {
                    height: 100%;
                }
            }

            h5 {
                line-height: line-height( 'xl' );
                margin-top: 0;
                margin-bottom: theme-spacing( 1 );
                font-size: font-size( 's' );
            }

            p {
                line-height: line-height( 'm' );
                font-size: font-size( 'xxs' );
            }
        }

        .gst-hotel-reservation__dates,
        .gst-hotel-reservation__services,
        .gst-hotel-reservation__cancellation-policies {
            padding-left: theme-spacing( 3 );
        }

        .gst-hotel-reservation__dates {
            div {
                span {
                    font-size: font-size( 'xxs' );
                }
            }
        }
    }
}

.gst-hotel-reservation__details__tooltip.v-tooltip__content {
    line-height: font-size( 'l' );
    padding: theme-spacing( 4 );
    background: theme-color( 'white' ) !important;
    color: theme-color( 'quaternary' );
    font-size: font-size( 's' );
    max-width: 350px;
    box-shadow: 0 2px 6px rgba( theme-color-hex( 'black' ), 0.25 );
    opacity: 1 !important;
}
</style>
