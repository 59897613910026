<template>
    <div class="gst-processout-input__container">
        <p class="gst-processout-input__label mb-2">
            {{ label }}
        </p>
        <div class="gst-processout-input__input d-flex align-center mb-2 pl-3"
            :class="statusClass"
            :data-processout-input="dataProcessOutInput"
            :data-processout-placeholder="placeholder">
            <slot>
            </slot>
            <BaseIcon v-show="isValid" symbol-id="icons--check_round" class="gst-processout-input__icon-checked" />
            <BaseIcon v-show="!isValid && isValid!==null" symbol-id="icons--exclamation_round" class="gst-processout-input__icon-exclamation" />
        </div>
        <slot name="error">
        </slot>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default{
        name: 'PaymentWidgetProcessoutInput',
        components: {
            BaseIcon
        },
        props: {
            label: {
                type: String,
                default: ''
            },
            dataProcessOutInput: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            isValid: {
                type: Boolean,
                default: null
            },
            isFocused: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            statusClass() {
                if ( this.isFocused && this.isValid===null ) {
                    return 'gst-processout-input__input-focused';
                } else if ( !this.isValid && this.isValid !== null ) {
                    return 'gst-processout-input__input-invalid';
                } else if ( this.isFocused && this.isValid ) {
                    return 'gst-processout-input__input-focused';
                }
                return ' ';
            }
        }
    };
</script>

<style lang="scss" scoped>

.gst-processout-input__container {
    .gst-processout-input__label {
        color: #1F262D;
    }

    .gst-processout-input__input {
        border: 1px solid theme-color( 'septenary' );
        box-shadow: inset 0 2px 4px rgb( 0 0 0 / 10% ) !important;
    }

    .gst-processout-input__input-focused {
        border: solid blue 1px;
    }

    .gst-processout-input__input-invalid {
        border: solid #CA2A2A 1px;
    }

    .gst-processout-input__icon-checked {
        position: absolute;
        right: 10px;
        height: 19px !important;
        width: 19px !important;

        path {
            fill: red !important;
        }
    }

    .gst-processout-input__icon-exclamation {
        position: absolute;
        right: 10px;
        height: 19px !important;
        width: 19px !important;
    }
}
</style>

<style lang="scss">
.gst-processout-input__icon-checked {
    position: absolute;
    right: 10px;
    height: 19px !important;
    width: 19px !important;

    path {
        fill: green !important;
    }
}
</style>
