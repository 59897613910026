<template>
    <div v-if="hasCartPackagesOrExperience">
        {{ $t( 'labels.termsEventTicket' ) }}
        <BaseUrlLink class="d-inline" :country="country" :language="language" url-link-key="termsOfUse" i18n-key="_common:linksLabels.termsOfUse" data-test-id="button.termsOfUse" />
        {{ $t( 'labels.termsEventTicketAnd') }}
        <BaseUrlLink class="d-inline" :country="country" :language="language" url-link-key="purchasePolicy" i18n-key="_common:linksLabels.purchasePolicy" data-test-id="button.purchasePolicy" />
        {{ $t( 'labels.termsEventTicketAndFinal') }}
        <TravelExperiencePolicyLink :is-package-offer="isPackageOffer" :country="country" :language="language" :data-test-id="$testId('button.travelExpPolicy')" target="_blank" />
    </div>
    <div v-else>
        {{ $t( 'labels.termsEventTicket' ) }}
        <BaseUrlLink class="d-inline" :country="country" :language="language" url-link-key="termsOfUse" i18n-key="_common:linksLabels.termsOfUse" data-test-id="button.termsOfUse" />
        {{ $t( 'labels.termsEventTicketAndFinal') }}
        <TravelExperiencePolicyLink :is-package-offer="isPackageOffer" :country="country" :language="language" :data-test-id="$testId('button.travelExpPolicy')" target="_blank" />
    </div>
</template>


<script>
    import purchaseConstants from '@tenants/ticketmaster/app/utils/constants/purchase';
    import TravelExperiencePolicyLink from '@tenant/app/components/links/TravelExperiencePolicyLink';
    import BaseUrlLink from '@tenants/ticketmaster/app/components/links/BaseUrlLink.vue';

    export default {
        name: 'TermsLabel',
        components: {
            TravelExperiencePolicyLink,
            BaseUrlLink
        },
        props: {
            purchaseFlow: {
                type: String,
                default: ''
            },
            country: {
                type: String,
                default: ''
            },
            language: {
                type: String,
                default: ''
            },
            isPackageOffer: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hasCartPackagesOrExperience( ) {
                return this.purchaseFlow === purchaseConstants.CART.FLOWS.PACKAGES
                    || this.purchaseFlow === purchaseConstants.CART.FLOWS.EXPERIENCES;
            }
        }
    };
</script>