<template>
    <div class="gst-checkout">
        <HeaderWithCountDown :hotel-reservation-only="hasHotelReservationOnly" :date-time-till="cart.expireAt" @click-logo="leave( true )" @finish="expire()" />
        <div v-if="cart && cart.id && !loading" class="row d-flex justify-center mx-auto" :style="{ 'max-width' : '1034px' }">
            <div class="col col-12 col-md-7 no-gutters py-8">
                <PaymentProviderDownContainer
                    v-if="showPaymentProviderDownContainer"
                    @back="leave"
                    @reload="onReloadPaymentProviderDo" />
                <div v-else>
                    <template v-if="!$vuetify.breakpoint.mdAndUp">
                        <ExperienceContainer
                            v-if="isExperienceOffer"
                            v-bind="propsExperience" />
                        <template v-else>
                            <EventTicketContainer
                                v-if="hasTicket"
                                v-bind="propsEventTicket" />
                            <HotelReservationContainer
                                v-if="hasHotelReservation"
                                :has-hotel-reservation-only="hasHotelReservationOnly"
                                :gift-card="giftCard"
                                v-bind="propsHotelReservation"
                                class="mt-4" />
                        </template>
                    </template>
                    <DetailFieldset
                        :customer-model="customerModel"
                        :validation="$v"
                        :extract-validations-messages-fn="_extractValidationsMessages" />
                    <PaymentFieldsetHotelAndTickets
                        ref="payment"
                        :customer-model="customerModel"
                        :payment-client-model="paymentClientModel"
                        :validation="$v"
                        :extract-validations-messages-fn="_extractValidationsMessages"
                        @open-client-payment-authorization="onOpenClientPaymentAuthorizationDo"
                        @close-client-payment-authorization="onCloseClientPaymentAuthorizationDo"
                        @load-client-payment-failed="paymentClientModel.errorContacting = true"
                        @updatePaymentFieldsValidity="value => validProviderPaymentFields = value"
                        @changeRequiredField="onChangeRequiredFieldDo" />
                    <ShipmentFieldset
                        v-if="hasShipping"
                        :shipping-model="shippingModel"
                        :validation="$v"
                        :extract-validations-messages-fn="_extractValidationsMessages" />
                    <CheckoutNotes
                        v-if="!!checkoutNotesModel && $vuetify.breakpoint.mdAndUp"
                        class="mt-4"
                        :header="checkoutNotesModel.data.package_flows__event_info_header"
                        :content="checkoutNotesModel.data.package_flows__event_info_content" />
                </div>
            </div>
            <div class="gst-checkout__cart-info col col-12 col-md-5 py-0 pr-md-8 py-8">
                <template v-if="!$vuetify.breakpoint.mdAndUp">
                    <EventHealthCheckContainer v-if="eventHealthCheck && !hasHotelReservationOnly" :health-check="eventHealthCheck" />
                </template>
                <Action
                    :agree-model="agreeModel"
                    :order="order"
                    :cart-collections="cartCollections"
                    :shipping-model="shippingModel"
                    :validation="$v"
                    :extract-validations-messages-fn="_extractValidationsMessages"
                    :total-price="totalPrice"
                    :event-health-check="eventHealthCheck"
                    :has-hotel-reservation-only="hasHotelReservationOnly"
                    :cart-has-ticket="hasTicket"
                    :is-payment-provider-down="isPaymentProviderDown"
                    :is-package-offer="isPackageOffer"
                    v-bind="propsHotelReservation"
                    class="mb-2"
                    @cancel="leave"
                    @submit="save" />
                <template v-if="$vuetify.breakpoint.mdAndUp">
                    <ExperienceContainer
                        v-if="isExperienceOffer"
                        v-bind="propsExperience" />
                    <template v-else>
                        <EventTicketContainer v-if="hasTicket" class="mt-4" v-bind="propsEventTicket" />
                        <HotelReservationContainer
                            v-if="hasHotelReservation"
                            :has-hotel-reservation-only="hasHotelReservationOnly"
                            :gift-card="giftCard"
                            v-bind="propsHotelReservation"
                            class="mt-4" />
                    </template>
                </template>
                <CheckoutNotes
                    v-if="!!checkoutNotesModel && $vuetify.breakpoint.smAndDown"
                    class="mt-4"
                    :header="checkoutNotesModel.data.package_flows__event_info_header"
                    :content="checkoutNotesModel.data.package_flows__event_info_content" />
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapGetters, mapState, mapActions } from 'vuex';
    import ticketConstants from '@core/utils/constants/ticket';
    import productsConstants from '@core/utils/constants/products';
    import apiConstants from '@core/utils/constants/api';
    import offerConstants from '@core/utils/constants/offer';
    import apiPaymentClientConstants from '@core/utils/constants/apiPaymentClient';
    import { getFromApiResponse as notificationUtilsGetFromApiResponse } from '@core/utils/notificationUtils';
    import apiServicePayments from '@core/api/apiServicePayments';
    import statesStoreModule from '@core/store/modules/states';
    import countriesStoreModule from '@core/store/modules/countries';
    import WarnBeforeLeave from '@core/mixins/WarnBeforeLeave';
    import zIndex from '@core/scss/mixins/_z-index-export-js.scss';
    import LogService from '@core/services/LogService.js';
    import DynamicStoreModule from '@core/mixins/DynamicStoreModule';
    import { redirectToHome  } from '@tenant/app/utils/tenantUrlslUtils';
    import ViewDocumentTitleMixin from '@tenant/app/mixins/ViewDocumentTitleMixin';
    import EventFlowMixin from '@tenant/app/mixins/EventFlowMixin';
    import FormMixin from './TheCheckoutFormMixin.js';
    import HeaderWithCountDown from './TheCheckout/HeaderWithCountDown.vue';
    import Action from './TheCheckout/Action.vue';
    import DetailFieldset from './TheCheckout/Fieldsets/DetailFieldset.vue';
    import PaymentFieldsetHotelAndTickets from './TheCheckout/Fieldsets/PaymentFieldsetHotelAndTickets.vue';
    import ShipmentFieldset from './TheCheckout/Fieldsets/ShipmentFieldset.vue';
    import EventHealthCheckContainer from './TheCheckout/Containers/EventHealthCheckContainer.vue';
    import EventTicketContainer from './TheCheckout/Containers/EventTicketContainer.vue';
    import HotelReservationContainer from './TheCheckout/Containers/HotelReservationContainer.vue';
    import ExperienceContainer from './TheCheckout/Containers/ExperienceContainer.vue';
    import PaymentProviderDownContainer from './TheCheckout/Containers/PaymentProviderDownContainer.vue';
    import CheckoutNotes from './TheCheckout/Containers/CheckoutNotes.vue';

    const STORE_NAME = 'cart';
    const STORE_COUNTRIES = 'countries';

    export default {
        name: 'TheCheckout',
        components: {
            HeaderWithCountDown,
            Action,
            DetailFieldset,
            PaymentFieldsetHotelAndTickets,
            ShipmentFieldset,
            EventTicketContainer,
            HotelReservationContainer,
            EventHealthCheckContainer,
            ExperienceContainer,
            PaymentProviderDownContainer,
            CheckoutNotes
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckout'
        },
        testIdOptions: {
            keyPrefix: 'checkout'
        },
        mixins:[
            FormMixin,
            WarnBeforeLeave,
            DynamicStoreModule,
            ViewDocumentTitleMixin,
            EventFlowMixin
        ],
        data( ) {
            return {
                loading: true,
                currentStep: 0,
                busEvents: new Vue( ),
                busEventProgressCircular: new Vue( ),
                paymentClientModel: {
                    token: null,
                    providerId: null,
                    errorContacting: false,
                },
                /**
                 * checkoutResponse is the order object
                 */
                checkoutResponse: false,
                testEventId: 106710000,
                cardInfo: {},
                validProviderPaymentFields: false,
                checkoutNotesModel: null,
                isCartInSubmitProcess: false,
                needsRefreshOnExit: false,
                /**
                 * browserHistoryLengthBeforeClientPaymentActivation
                 */
                browserHistoryLength: false
            };
        },
        computed: {
            ...mapGetters( {
                isCartExpired: `${STORE_NAME}/isExpired`,
                isCartSubmitting: `${STORE_NAME}/isCartSubmitting`,
                hasTicket: `${STORE_NAME}/hasTicket`,
                hasHotelReservation: `${STORE_NAME}/hasHotelReservation`,
                hasHotelReservationOnly: `${STORE_NAME}/hasHotelReservationOnly`,
                isCartSubmitted: `${STORE_NAME}/isSubmitted`,
                getCollections: `${STORE_NAME}/getCollections`
            } ),
            ...mapState( {
                cart:   state => state[STORE_NAME].current,
                countries: state => state[STORE_COUNTRIES].list
            } ),
            routeCartId( ) {
                return this.$route.params.cartId;
            },
            cartCollections( ) {
                return this.cart.collections;
            },
            tickets( ) {
                return this.cartCollections
                    .filter( item => item.productTypeId === productsConstants.TYPES.TICKET );
            },
            ticket( ) {
                return this.tickets[ 0 ];
            },
            ticketItem( ) {
                return this.ticket.items[0];
            },
            order( ) {
                return this.cart.order;
            },
            currency( ) {
                return this.order.currency;
            },
            hasResaleItems( ) {
                return !!this.tickets.find( item => item.selection === ticketConstants.TYPES.RESALE );
            },
            hotelReservation( ) {
                return this.cartCollections
                    .find( item => item.productTypeId === productsConstants.TYPES.HOTEL_RESERVATION );
            },
            eventHealthCheck( ) {
                return this.ticket && this.ticket.info.healthCheck;
            },
            propsEventTicket( ) {
                const { ticket, ticketItem, cart } = this;
                const { info } = ticket;

                return {
                    eventName: info.name,
                    eventAddress: {
                        venueName: info.venueName,
                        city: info.venueCity,
                        stateCode: info.venueStateCode,
                        street: info.venueStreet,
                        countryCode: info.venueCountryCode
                    },
                    eventStartDate: info.startDateTime,
                    eventEndDate: info.endDateTime,
                    eventStartDateHasTime: info.hasStartTime,
                    bannerImage: info.imageUrl,
                    ticketItem: ticketItem,
                    venueSeatMapUrl: cart.venueSeatMapUrl
                };
            },
            propsHotelReservation( ) {
                const { hotelReservation } = this;

                return {
                    accommodation: hotelReservation.accommodation,
                    hotel: {
                        name: hotelReservation.hotel.name,
                        image: hotelReservation.hotel.image,
                        address: hotelReservation.hotel.address,
                        policy: hotelReservation.hotel.policy,
                        roomRate: hotelReservation.totalPrice,
                        packageOffer: this.packageOffer
                    },
                    room: {
                        name: hotelReservation.hotel.room.name,
                        rate : {
                            mealPlan: hotelReservation.hotel.room.rate.mealPlan,
                            cancellationPolicyDescription: hotelReservation.hotel.room.rate.cancellationPolicyDescription,
                            cancellationPolicies: hotelReservation.hotel.room.rate.cancellationPolicies,
                            additionalNotes: hotelReservation.hotel.room.rate.additionalComments
                        }
                    },
                    currency: this.order.currency,
                    isPackageOffer: this.isPackageOffer
                };
            },
            propsExperience( ) {
                const { hotelReservation, ticket, ticketItem, packageOffer } = this;
                const { hotel } = hotelReservation;
                const { info } = ticket;

                return  {
                    name: packageOffer.name,
                    imageUrl: packageOffer.imageUrl,
                    highlights: packageOffer.highlights,
                    hotel: {
                        name: hotel.name,
                        award: hotel.award,
                    },
                    hotelReservation: {
                        accommodation: hotelReservation.accommodation,
                        room: {
                            name: hotelReservation.hotel.room.name,
                        },
                    },
                    event: {
                        address: {
                            venueName: info.venueName,
                            city: info.venueCity,
                            stateCode: info.venueStateCode,
                            street: info.venueStreet,
                            countryCode: info.venueCountryCode
                        },
                        startDate: info.startDateTime,
                        endDate: info.endDateTime,
                        startDateHasTime: info.hasStartTime,
                    },
                    eventTicket: ticketItem,
                };
            },
            totalPrice( ) {
                const { shippingModel, order } = this;
                const delivery = shippingModel.options.find( value => value.id === shippingModel.optionId );
                const totalWithoutDelivery = order.totalPrice - order.totalDelivery;

                if ( !delivery ) {
                    return totalWithoutDelivery;
                }
                return totalWithoutDelivery + delivery.totals.fee;
            },
            isPackageOffer( ) {
                return !!( this.ticket && this.ticket.packageOfferId );
            },
            packageOffer( ) {
                return this.cart.packageOffer;
            },
            isExperienceOffer( ) {
                return this.isPackageOffer && this.packageOffer.type === offerConstants.TYPES.EXPERIENCES_PACKAGE;
            },
            showPaymentProviderDownContainer( ) {
                const { paymentClientModel, loading } = this;

                return paymentClientModel.errorContacting && !loading;
            },
            isPaymentProviderDown( ) {
                const { paymentClientModel } = this;

                return paymentClientModel.errorContacting;
            },
            hasShipping( ) {
                return !this.hasHotelReservationOnly; //TODO: this will be decided by BE, not based on hasHotelReservationOnly
            },
            paymentProviderWidget( ) {
                const payment = this.$refs.payment;
                return payment ? payment.$refs.paymentWidget : null;
            },
            needInvoiceForAuthorization( ) {
                return this.paymentClientModel.providerId === apiPaymentClientConstants.PROVIDERS.PROCESSOUT;
            },
            giftCard( ) {
                return this.cart.giftCard;
            },
            getProcessoutPayload( ) {
                const { firstName, lastName, email, address, city, country } = this.customerModel ?? {};

                return {
                    firstName: firstName ?? null,
                    lastName: lastName ?? null,
                    email: email ?? null,
                    address: address ?? null,
                    city: city ?? null,
                    countryCode: country ?? null,
                };
            }
        },
        watch: {
            'customerModel.country': {
                handler: function ( value, oldValue ) {
                    if ( value ) {
                        if ( this.hasShipping && value !== oldValue ) {
                            this.loadShippingOptionsForCountry( );
                        }
                    } else {
                        this.clearShippingOptions( );
                    }
                },
                deep: true
            },
        },
        methods: {
            ...mapActions( {
                notificationWarning:        'notification/warning',
                notificationError:          'notification/error',
                showLoadingOverlay:         'appState/setLoading',
                checkoutCart:               'cart/checkout',
                instantDeleteCurrentCart:   'cart/instantDeleteCurrentCart',
                clearCart:                  'cart/clear',
                addShippingOption:          'cart/addShippingOption',
                getShippingOptions:         'cart/getShippingOptions',
                getCart:                    'cart/get',
                setCartOrder:               'cart/setCartOrder',
                createInvoice:              'cart/createInvoice',
                getCountries:               `${STORE_COUNTRIES}/get`,
                updateLanguagesForEvent: 'languages/updateLanguagesForEvent'
            } ),
            expire( ) {
                const doExpire = () => {
                    this.unbindWarnBeforeYouLeaveMixin( );
                    this.showExpireErrorMessage( );
                    this.$router.back( );
                };

                if ( this.isCartSubmitting ) {
                    const unwatch = this.$watch( 'isSubmitting',
                                                 ( value ) => {
                                                     if ( !value && this.cart.id ) {
                                                         doExpire();
                                                     }
                                                     unwatch();
                                                 } );
                } else {
                    doExpire();
                }
            },
            leave( goToHome = false ) {
                this.$modal.show(
                    ( ) => import( './TheCheckout/ConfirmLeaveModal' ),
                    {
                        onConfirmFn: ( ) => {
                            this.unbindWarnBeforeYouLeaveMixin( );
                            this.instantDeleteCurrentCart();
                            if ( goToHome ) {
                                redirectToHome( );
                            } else {
                                this.$router.back( );
                            }
                        }
                    },
                    {
                        'max-width': 800,
                        persistent: true,
                        'no-click-animation': true
                    }
                );
                return;
            },
            async save( ) {
                const legacyEventId = this.ticket ? this.ticket.legacyEventId : '';
                const scrollToFirstErrorField = ( ) => {
                    const field = document.querySelectorAll( `[data-has-error='true']` ) [ 0 ];

                    field && field.scrollIntoView && field.scrollIntoView( {
                        behavior: 'smooth',
                        block: 'start'
                    } );
                };
                let statePurchase = 0;

                this.$v.$touch();
                if ( !this.validProviderPaymentFields ) {
                    this.paymentProviderWidget.showErrors( );
                }

                if ( this.$v.$invalid || !this.validProviderPaymentFields ) {
                    this.$nextTick( scrollToFirstErrorField );
                    return;
                }

                const getPayload = ( card ) => {
                    const { firstName, lastName, email, address, zip, city, state, country, phone, hotelReservationRequest } = this.customerModel;
                    const { invoiceId, ...restCard } = card;

                    const cardInfo = {
                        ...restCard,
                        paymentType: this.paymentClientModel.providerId
                    };

                    const payload = {
                        user:{
                            firstName,
                            lastName,
                            email,
                            phone
                        },
                        address: Object.assign( {
                            street: address,
                            city,
                            zip,
                            countryCode: country,
                            stateCode: state || ''
                        }, this.hasResaleItems ? { phone } : null ),
                        card: cardInfo,
                        redeem: {
                            money: this.totalPrice,
                            points: 0
                        },
                        remark: hotelReservationRequest,
                        hotelRoom: {
                            roomRate: {
                                totalPrice: this.order.totalPrice,
                            }
                        }

                    };

                    invoiceId && ( payload.payment = { invoiceId } );

                    if( this.oldTotalPrice ) {
                        payload.hotelRoom.roomRate.oldTotalPrice = this.oldTotalPrice;
                    }
                    return payload;
                };

                const onCheckoutErrorDo = async ( response ) => {
                    const hotelProviderRelatedErrorCodes = Object.values( apiConstants.ERROR_CODES.HOTEL_PROVIDER ).filter( code => code !== apiConstants.ERROR_CODES.HOTEL_PROVIDER.ROOM_RATE_CHANGED );
                    const { code } = response;

                    const i18Prefix = '_common:messageServerErrorCodesFromContext.purchase';

                    if ( !hotelProviderRelatedErrorCodes.includes( code ) ) {
                        await this.initPaymentClient( );
                        this.busEventProgressCircular.$emit( 'end' );
                    } else {
                        this.unbindWarnBeforeYouLeaveMixin( );
                        this.instantDeleteCurrentCart();
                        this.$router.back( );
                    }

                    switch ( code ) {
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.PRODUCT_ERROR:
                            this.notificationWarning( {
                                title: this.$t( `${i18Prefix}.${code}.title` ),
                                content: this.$t( `${i18Prefix}.${code}.detail` ),
                                autoDismiss: false
                            } );
                            break;
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.INVALID_REQUEST:
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.INVALID_DATA:
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.SYSTEM_ERROR:
                            this.notificationError( {
                                title: this.$t( `${i18Prefix}.hotelProviderDefault.title` ),
                                content: this.$t( `${i18Prefix}.hotelProviderDefault.detail` )
                            } );
                            break;
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.ROOM_RATE_CHANGED:
                            this.openHotelRateChangedModal( response );
                            break;
                        case apiConstants.ERROR_CODES.EVENT_PROVIDER.INVALID_CART_STATE:
                        case apiConstants.ERROR_CODES.TRANSACTION_BLOCKED:
                            this.notificationError( notificationUtilsGetFromApiResponse( response ), { root: true }  );
                            this.unbindWarnBeforeYouLeaveMixin( );
                            this.instantDeleteCurrentCart();
                            this.$router.replace( { name: 'home' } );
                            break;
                        default:
                            this.notificationError( notificationUtilsGetFromApiResponse( response ) );
                            break;
                    }
                };

                this.$modal.show(
                    import ( '@tenants/ticketmaster/app/components/modals/ProgressCircularAnimationModal.vue' ),
                    {
                        title: this.$t( 'progressCircularAnimationModal.title' ),
                        busEvent: this.busEventProgressCircular,
                        endOnRouteChange: false,
                        variant: 1
                    },
                    {
                        style: `z-index: ${zIndex.highest}`,
                        'no-click-animation': true,
                        persistent: true,
                        scrollable: false,
                        fullscreen: false,
                        'hide-overlay': false,
                        'max-width': 600
                    },
                    {
                        open: async ( ) => {
                            const onErrorDo = ( msg ) => {
                                this.busEventProgressCircular.$emit( 'close' );
                                this.notificationError( msg );
                            };
                            let invoiceId;

                            //1. Add shipping
                            if( this.hasShipping && !await this.saveShippingOption( ) ) {
                                return onErrorDo( this.$t( '_components.fieldsets.paymentFieldset.errors.noShippingOptionsForCountry' ) );
                            }
                            if ( this.needInvoiceForAuthorization ) {
                                const responseInvoice = await this.createInvoice( { id: this.cart.id, data: this.getProcessoutPayload } );
                                if ( responseInvoice.success ) {
                                    invoiceId = responseInvoice.data.id;
                                } else {
                                    return onErrorDo( {
                                        title: this.$t( '_components.containers.paymentProviderDownContainer.title' ),
                                        content: this.$t( '_components.containers.paymentProviderDownContainer.message' )
                                    } );
                                }
                            }
                            //2. Submit payment form
                            const responsePayment = await this.paymentProviderWidget.submit( invoiceId );
                            if ( !responsePayment.success ) {
                                return onErrorDo( responsePayment.message );
                            }
                            this.busEventProgressCircular.$emit( 'go-to-step', 3 );
                            const data = getPayload( responsePayment.data );
                            this.oldTotalPrice = 0;
                            this.cardInfo = { ...data };
                            //3. Submit form
                            this.checkoutCart( { id: this.cart.id, data } )
                                .then( response => {
                                    if ( response.success ) {
                                        this.checkoutResponse = response;
                                        this.busEventProgressCircular.$emit( 'end' );
                                        this.unbindWarnBeforeYouLeaveMixin( );
                                        statePurchase = statePurchase + 1;

                                        if ( statePurchase === 2 ) {
                                            this.openOrderConfirmation ( this.checkoutResponse, legacyEventId );
                                        }
                                    } else {
                                        onCheckoutErrorDo( response );
                                    }
                                } ).catch( ( e ) => {
                                    LogService.error( e.message );
                                    onErrorDo( this.$t( 'messages.formHasError' ) );
                                }
                            );
                        },
                        beforeClose: ( ) => {
                            statePurchase = statePurchase + 1;

                            if ( statePurchase === 2 ) {
                                this.openOrderConfirmation ( this.checkoutResponse, legacyEventId );
                            }
                        }
                    }
                );
            },
            openOrderConfirmation( order, legacyEventId ) {
                this.$router.replace( {
                    name: 'OrderConfirmation',
                    params: {
                        uuid: order.uuid,
                        order: order,
                        checkoutNotes: this.checkoutNotesModel,
                        legacyEventId: legacyEventId
                    }
                } );
            },
            openHotelRateChangedModal( response ) {
                const { detail } = response;
                const currency = this.currency || this.hotelReservation.currency;
                const hotelRoomOldTotalPrice = this.$options.filters.currencyFilter( detail.hotelRoom.oldTotalPrice || 0, currency );
                const hotelRoomNewTotalPrice = this.$options.filters.currencyFilter( detail.hotelRoom.newTotalPrice || 0, currency );
                const i18nPrefix = 'main:views.cart.theCheckout._components.hotelRateChangedModal';
                const title = this.$t( `${i18nPrefix}.title` );
                let content;
                if ( this.isHotelOnlyFlow ) {
                    content = this.$t(
                        `${i18nPrefix}.contentHotelOnly`,
                        {
                            oldPrice: hotelRoomOldTotalPrice,
                            newPrice: hotelRoomNewTotalPrice,
                        } );
                } else {
                    content = this.$t(
                        `${i18nPrefix}.content`,
                        {
                            oldPrice: hotelRoomOldTotalPrice,
                            newPrice: hotelRoomNewTotalPrice,
                        } );
                }
                const confirmButton = this.$t( `${i18nPrefix}.buttons.confirm` );

                this.$modal.show(
                    ( ) => import( '@tenants/ticketmaster/app/components/modals/ConfirmationModal' ),
                    {
                        title,
                        content,
                        confirmButton,
                        onConfirmFn: ( ) => this.onHotelRateChangedConfirmDo( ),
                    },
                    {
                        'max-width': 858,
                        persistent: true,
                    }
                );
            },
            showExpireErrorMessage( ) {
                this._cartExpiredErrorShowed || this.notificationError( this.$t( '_common:messages.cartExpired' ) );
                this._cartExpiredErrorShowed = true;
            },
            onLoadingPaymentProviderError( ) {
                this.showLoadingOverlay( false );
                this.unbindWarnBeforeYouLeaveMixin( );
                this.notificationError( this.$t( '_common:messages.apiPaymentClientContactingError' ) );
                redirectToHome();
            },
            async onReloadPaymentProviderDo( ) {
                this.showLoadingOverlay( true );
                await this.initPaymentClient( );
                this.showLoadingOverlay( false );
            },
            async saveShippingOption() {
                const { optionId, lastSubmittedOptionId } = this.shippingModel;
                const selectedShipping = this.shippingModel.options.find( option => option.id === optionId );

                if ( optionId !== lastSubmittedOptionId ) {
                    this.showLoadingOverlay( true );
                    const responseAddShippingOption = await this.addShippingOption( { id: this.cart.id, data: selectedShipping } );
                    if ( responseAddShippingOption ) {
                        this.shippingModel.lastSubmittedOptionId = this.shippingModel.optionId;
                        this.showLoadingOverlay( false );
                        return true;
                    } else {
                        this.showLoadingOverlay( false );
                        return false;
                    }
                }

                if ( this.paymentClientModel.errorContacting ) {
                    this.showLoadingOverlay( true );
                    await this.initPaymentClient( );
                }

                this.showLoadingOverlay( false );
                return true;
            },
            async initPaymentClient( ) {
                const api = await apiServicePayments( '' );
                const paymentClientResponse = await api.payments.getClientToken( this.cart.id );
                if ( !paymentClientResponse.success ) {
                    this.paymentClientModel.errorContacting = true;
                    return this.paymentClientModel;
                }
                this.paymentClientModel.token       = paymentClientResponse.data.clientToken;
                this.paymentClientModel.providerId  = paymentClientResponse.data.providerId;
                this.paymentClientModel.errorContacting = false;

                return this.paymentClientModel;
            },
            onHotelRateChangedConfirmDo( ) {
                this.unbindWarnBeforeYouLeaveMixin( );
                this.$router.back( );
            },
            onOpenClientPaymentAuthorizationDo( ) {
                this.needsRefreshOnExit = true;
                this.browserHistoryLength = history.length;
            },
            onCloseClientPaymentAuthorizationDo( ) {
                this.needsRefreshOnExit = false;
                if ( this.browserHistoryLength < history.length ) {
                    !this._destroyed && this.$router.go( this.browserHistoryLength - history.length );
                }
            },
            async loadCart() {
                await this.getCart( { id: this.cart.id } );
            }
        },
        async created( ) {
            this.registerStoreModule( 'states', statesStoreModule );
            this.registerStoreModule( 'countries', countriesStoreModule );
            const checkCart = ( ) => {
                if ( !this.cart.id || this.routeCartId != this.cart.id || this.isCartSubmitted ) {
                    return;
                }

                if ( this.isCartExpired( ) ) {
                    this.showExpireErrorMessage( );
                    return;
                }
                return true;
            };

            this.showLoadingOverlay( true );
            this.forceWarnBeforeLeaveMixin( );
            if ( !checkCart( ) ) {
                this.unbindWarnBeforeYouLeaveMixin( );
                this.showLoadingOverlay( false );
                redirectToHome( );
                return;
            }
            this.getCountries( );
            await this.loadCart( );
            if ( this.ticket ) {
                this.updateLanguagesForEvent( this.ticket.legacyEventId );
            }
            this.updateDocumentTitle ( this.$t(
                'main:viewsTitle.theEvent.experiences',
                { package: this.hotelReservation.hotel.name }
            ) );
            if( this.isPackageOffer ) {
                try {
                    this.checkoutNotesModel = await this.$prismic.client.getByUID( 'event-info-content', this.ticket.legacyEventId.toLowerCase() );
                } catch ( e ) {
                    LogService.log( e.message );
                }
            }
            await this.initPaymentClient( );
            this.updateFormModelAfterLoadingCart( );
            if( this.hasShipping ) {
                await this.loadShippingOptionsForCountry( );
            }
            this.showLoadingOverlay( false );
            this.loading = false;
        },
        destroyed( ) {
            this._destroyed = true;
        },
        beforeRouteLeave ( to, from, next ) {
            if ( !this.warnBeforeLeaving ) {
                setTimeout( ( ) => {
                    this.needsRefreshOnExit && location.reload();
                }, 10 ),
                next( );
                return;
            }

            const answer = window.confirm( this.warnBeforeLeaveMessage );
            if ( answer ) {
                window.onbeforeunload = null;
                setTimeout( ( ) => {
                    this.needsRefreshOnExit && location.reload();
                }, 10 ),
                next( );
            } else {
                next( false );
            }
        },
    };
</script>

<style lang="scss">
    @import "@scssMixins";
    @import "@scssVariables";

    @include mobile-only {
        .gst-checkout {
            .gst-checkout__cart-info {
                padding: theme-spacing( 0, 3, 1, 3 ) !important;
                margin-top: theme-spacing( n4 ) !important;
            }
        }
    }

</style>
