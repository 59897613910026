<template>
    <div class="gst-experience-container d-md-block">
        <div class="gst-experience-container__details d-flex flex-row flex-md-column justify-md-center">
            <div class="gst-experience-container__details__banner u-position-relative d-flex flex-column justify-center d-md-block">
                <BaseImage
                    :src="imageUrl"
                    :fallback-src="imageUrl"
                    class="gst-experience-container__image"
                    :cover="$vuetify.breakpoint.mdAndUp"
                    :container="!$vuetify.breakpoint.mdAndUp" />
            </div>
            <div class="gst-experience-container__details__text">
                <div class="d-flex flex-row align-center justify-space-between mt-3 mb-1 mt-md-6 mb-md-4">
                    <h5>{{ name || hotel.name }}</h5>
                    <StarRating :value="hotel.award.rating" class="star-rating d-flex align-center" />
                </div>
                <div class="d-flex flex-row align-center mb-1 mb-md-2">
                    <BaseIcon v-if="!isMobile" symbol-id="icons--calendar" class="mr-1" />
                    <p>{{ eventDate }}</p>
                </div>
                <div class="d-flex flex-row align-center">
                    <BaseIcon v-if="!isMobile" symbol-id="icons--location" class="mr-3" />
                    <p>{{ eventLocation }}</p>
                </div>
            </div>
        </div>
        <div class="gst-experience-container__highlights d-flex flex-column mt-4">
            <h6>{{ $t( '_common:terms.yourExperienceIncludes') }}:</h6>
            <Highlights :data="highlightsItems" />
        </div>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import StarRating from '@core/shared/components/misc/StarRating';
    import Highlights from '@tenants/ticketmaster/app/components/misc/Highlights/Highlights.vue';
    import highlightTypeConstants from '@tenants/ticketmaster/app/utils/constants/highlights';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import {
        getDescriptionForHotel as getPackageHighlightDescriptionForHotel,
        getDescriptionForTicket as getPackageHighlightDescriptionForTicket
    } from '@tenants/ticketmaster/app/utils/packageHighlightsUtils';
    import { sortAndCombineLists as sortAndCombineHighlights } from '@tenant/app/utils/highlightsUtils';

    export default {
        name: 'HotelReservationContainer',
        components: {
            BaseImage,
            StarRating,
            BaseIcon,
            Highlights
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckout._components.containers.hotelReservationContainer'
        },
        props: {
            name: {
                type: String,
                default: ''
            },
            imageUrl: {
                type: String,
                default: '',
            },
            highlights: {
                type: Array,
                default: ( ) => [ { type: '', description: '' } ]
            },
            hotel: {
                type: Object,
                default: ( ) => { return { name: '', award: { } }; }
            },
            hotelReservation: {
                type: Object,
                default: ( ) => { return { accommodation: { }, room: { name : '' } }; }
            },
            event: {
                type: Object,
                default: ( ) => { return { address: { }, startDate: new Date( ), endDate: new Date( ), startDateHasTime: true }; }
            },
            eventTicket: {
                type: Object,
                default: ( ) => { return { }; }
            }
        },
        computed: {
            isMobile( ) {
                return this.$vuetify.breakpoint.smAndDown;
            },
            eventDate() {
                const { startDate, endDate, startDateHasTime } = this.event;

                return this.$options.filters.eventPeriod( startDate, endDate, startDateHasTime );
            },
            eventLocation( ) {
                const { address } = this.event;

                return `${address.venueName}, ${address.city}, ${address.stateCode}`;
            },
            hotelReservationPeriod( ) {
                const { accommodation } = this.hotelReservation;

                return this.$options.filters.reservationDatePeriod(
                    new Date( accommodation.startDateTime ),
                    new Date( accommodation.endDateTime ),
                    true
                );
            },
            packageHighlightForHotel( ) {
                return getPackageHighlightDescriptionForHotel( this.highlights )
                    || this.$t( '_common:terms.oneNightStayInDoubleRoom' );
            },
            packageHighlightForTicket( ) {
                return getPackageHighlightDescriptionForTicket( this.highlights )
                    || this.$t( '_common:terms.twoStandardTickets' );
            },
            highlightsItems( ) {
                const initialItems = [
                    {
                        type: highlightTypeConstants.TYPES.HOTEL,
                        description: this.packageHighlightForHotel,
                        subtitle: this.hotelReservationPeriod
                    },
                    {
                        type: highlightTypeConstants.TYPES.TICKET,
                        description: this.packageHighlightForTicket
                    }
                ];

                return sortAndCombineHighlights( initialItems, this.highlights );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-experience-container {
    @include border-radius('xs');

    padding: theme-spacing( 4 ) theme-spacing( 2 );
    border: 1px solid theme-color( 'septenary' ) !important;

    .gst-experience-container__details__banner {
        margin: theme-spacing( 'n4' ) theme-spacing( 'n2' ) 0;
        flex: 1;

        .gst-experience-container__image {
            max-height: 215px;
        }

        .fallback-image {
            height: 215px;
        }
    }

    .gst-experience-container__details {
        .gst-experience-container__details__text {
            flex: 1;

            h5 {
                line-height: line-height( 'xxxl' );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'large' );
            }

            p {
                line-height: line-height( 'xl' );
                margin-bottom: theme-spacing( 0 );
                color: theme-color( 'tertiary' );
                font-size: font-size( 's' );
                font-weight: font-weight( 'regular' );
            }
        }
    }

    .gst-experience-container__highlights {
        h6 {
            color: theme-color( 'black' );
            font-size: font-size( 's' );
            font-weight: font-weight( 'large' );
        }

        .gst-highlight-item {
            color: theme-color( 'tertiary' ) !important;
        }
    }

    @include mobile-only {
        padding: theme-spacing( 4 ) theme-spacing( 2 );

        .gst-experience-container__details {
            .gst-experience-container__details__banner {
                margin: 0 theme-spacing( 4 ) 0 0;
                max-width: 30%;

                .fallback-image {
                    height: 100%;
                }
            }

            .gst-experience-container__details__text {
                h5 {
                    line-height: line-height( 'xl' );
                    margin-top: 0;
                    margin-bottom: theme-spacing( 1 );
                    font-size: font-size( 's' );
                }

                p {
                    line-height: line-height( 'm' );
                    font-size: font-size( 'xxs' );
                }
            }
        }

        .gst-experience-container__highlights {
            h6 {
                font-size: font-size( 'xxs' );
            }

            .gst-highlight-item {
                font-size: font-size( 'xxs' );
            }
        }
    }
}
</style>
