<template>
    <div class="gst-additional-notes-modal">
        <HeaderVariant1
            class="flex-grow-0"
            :title="$t( 'title' )"
            @close="$emit('close')" />
        <div class="gst-additional-notes-modal__content">
            <p> {{ content }} </p>
        </div>
    </div>
</template>

<script>
    import HeaderVariant1 from '@tenant/app/components/modals/HeaderVariant1';

    export default ( {
        name: 'AdditionalNotesModal',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'modules.hotel.modal._components._components.hotelAdditionalNotesModal'
        },
        components: {
            HeaderVariant1
        },
        props: {
            content: {
                type: String,
                default: ''
            }
        }
    } );
</script>

<style lang="scss" scoped>
@import "@scssMixins";
@import "@scssVariables";

.gst-additional-notes-modal {
    height: auto;
    background-color: theme-color( 'white' );

    .gst-additional-notes-modal__content {
        padding: theme-spacing( 8 );
        padding-right: theme-spacing( 10 );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'regular' );
        word-break: break-word;
    }
}

@include mobile-only {
    .gst-additional-notes-modal {
        height: 70vh;

        .gst-additional-notes-modal__content {
            padding: theme-spacing( 6 );
            font-size: font-size( 'l' ) !important;
            font-weight: font-weight( 'regular' );
        }
    }
}
</style>
