<template>
    <a :href="href" :data-test-id="$testId(`${dataTestId}`)" target="_blank">
        <slot>{{ $t( `${i18nKey}`) }}</slot>
    </a>
</template>

<script>
    import get from 'lodash/get';
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        name: 'BaseUrlLink',
        props: {
            country: {
                type: String,
                default: ''
            },
            language: {
                type: String,
                default: 'en'
            },
            urlLinkKey: {
                type: String,
                default: 'purchasePolicy'
            },
            dataTestId: {
                type: String,
                default: '',
                required: true
            },
            i18nKey: {
                type: String,
                default: '',
                required: true
            }
        },
        computed: {
            href( ) {
                let link;
                if ( tenantUrlsConstants.COUNTRIES.hasOwnProperty( this.country ) ) {
                    link = get( tenantUrlsConstants.COUNTRIES, [ this.country, this.urlLinkKey, this.language ] );
                    if ( !link ) {
                        link = get( tenantUrlsConstants.COUNTRIES, [ this.country, this.urlLinkKey, tenantUrlsConstants.COUNTRIES[this.country].defaultLanguage ] );
                    }
                }
                if ( !link ) {
                    link = tenantUrlsConstants[ this.urlLinkKey ];
                }
                return link;
            }
        }
    };

</script>
