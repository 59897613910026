export function zipCA ( value ) {
    return !!value.match( /^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$/ ) || !!value.match( /^[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]$/ );
}

export function phoneNumber ( value ) {
    return !!value.match( /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ );
}

export function address( value ) {
    return !value.match( /[^a-zA-Z0-9 ,.-]/g );
}

export function city( value ) {
    return !value.match( /[^a-zA-Z ,.-]/g  );
}