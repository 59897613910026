<template>
    <div>
        <PaymentWidgetProcessOut
            v-if="type === providers.PROCESSOUT"
            ref="payment"
            :token="token"
            @load-client-failed="$emit( 'load-client-failed', $event )"
            @load-client-success="$emit( 'load-client-success', $event )"
            @update-payment-fields-validity="onUpdatePaymentFieldsValidityDo"
            v-on="$listeners" />
        <PaymentWidgetBraintree
            v-if="type === providers.BRAINTREE"
            ref="payment"
            :key="token"
            :token="token"
            class="col col-12 py-0"
            @load-client-failed="$emit( 'load-client-failed', $event )"
            @load-client-success="$emit( 'load-client-success', $event )"
            @open-authorization="$emit( 'open-authorization', $event)"
            @close-authorization="$emit( 'close-authorization', $event)"
            @update-payment-fields-validity="onUpdatePaymentFieldsValidityDo" />
    </div>
</template>

<script>
    import apiPaymentClientConstants from '@core/utils/constants/apiPaymentClient';
    import PaymentWidgetProcessOut from './PaymentWidgetProcessOut.vue';
    import PaymentWidgetBraintree from './PaymentWidgetBraintree.vue';

    export default {
        name: 'PaymentWidget',
        components: {
            PaymentWidgetProcessOut,
            PaymentWidgetBraintree
        },
        props: {
            token: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: false,
                default: apiPaymentClientConstants.PROVIDERS.PROCESSOUT,
                validator: ( value ) => {
                    return Object.values( apiPaymentClientConstants.PROVIDERS ).includes( value );
                }
            },
        },
        emits: [
            'update-payment-fields-validity',
            'load-client-failed',
            'load-client-success',
            'open-authorization',
            'close-authorization',
        ],
        computed: {
            providers( ) {
                return apiPaymentClientConstants.PROVIDERS;
            },
        },
        methods: {
            /**
             * @description Submit the form
             * @param {String} invoiceId
             * @returns {Promise<void>}
             */
            submit( invoiceId ) {
                return this.$refs.payment.submit( invoiceId );
            },
            showErrors( ) {
                return this.$refs.payment.showErrors( );
            },
            init( ) {
                this.$refs.paymentWidget.init( );
            },
            onUpdatePaymentFieldsValidityDo( validPaymentFields ) {
                this.$emit( 'update-payment-fields-validity', validPaymentFields );
            }
        }
    };
</script>
