export default {
    METHODS: {
        /**
         * Pay only with points is a particulat case of split
         */
        POINTS: '1',
        CARD: '2',
        SPLIT: '3'
    },
    METHOD_API: {
        2: 'CASH',
        3: 'SPLIT'
    }
};
