<template>
    <input
        type="checkbox"
        class="gst-base-checkbox"
        :aria-label="dataAriaLabel"
        :class="[hasError && 'input-error']"
        v-bind="$attrs"
        :checked="value === onValue"
        :data-has-error="hasError"
        @input="onInputDo"
        @blur="$emit( 'blur' )"
        @focus="$emit( 'focus' )" />
</template>

<script>
    export default {
        name: 'BaseCheckbox',
        props: {
            dataAriaLabel: {
                type: String,
                default: ''
            },
            value: {
                type: [ String, Number, Object, Boolean ],
                default: null
            },
            onValue: {
                type: [ String, Number, Object, Boolean ],
                default: function ( ) {
                    return true;
                }
            },
            offValue: {
                type: [ String, Number, Object, Boolean ],
                default: function ( ) {
                    return false;
                }
            },
            hasError: {
                type: Boolean,
                default: false
            },
            dataTestId: {
                type: String,
                required: true
            }
        },
        methods: {
            onInputDo( event ) {
                const value = event.target.checked;

                this.$emit( 'input', value ? this.onValue : this.offValue );
            }
        }
    };
</script>