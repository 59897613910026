import { mapGetters } from 'vuex';
import purchaseConstants from '@tenants/ticketmaster/app/utils/constants/purchase';

export default {
    computed: {
        ...mapGetters( {
            cartPurchaseFlow: 'cart/purchaseFlow',
        } ),
        isCartDynamicFlow( ) {
            return this.cartPurchaseFlow === purchaseConstants.CART.FLOWS.DYNAMIC;
        },
        isCartPackagesFlow( ) {
            return this.cartPurchaseFlow === purchaseConstants.CART.FLOWS.PACKAGES;
        },
        isCartExperiencesFlow( ) {
            return this.cartPurchaseFlow === purchaseConstants.CART.FLOWS.EXPERIENCES;
        },
        isCartPreAllocatedFlow( ) {
            return this.cartPurchaseFlow === purchaseConstants.CART.FLOWS.HOTELS_PRE_ALLOCATED;
        },
        isCartOpenInventoryFlow( ) {
            return this.cartPurchaseFlow === purchaseConstants.CART.FLOWS.HOTELS_OPEN_INVENTORY;
        },
    }
};
