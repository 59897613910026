<template>
    <div
        v-if="items.length"
        class="gst-checkout-terms__checkboxes">
        <div
            v-for="(item, index) in items"
            :key="`label_${index}`"
            class="gst-checkout-terms__checkboxes__item d-flex mb-2">
            <BaseFormCheckbox
                :id="`terms_${index}`"
                v-model="agreeModel[`terms_${index}`]"
                hide-error-container
                :data-test-id="`terms_${index}`">
                <template slot="label">
                    <PrismicRichText :field="item.label" />
                </template>
            </BaseFormCheckbox>
            <BaseTooltip
                v-if="item.tooltip"
                :text="item.tooltip"
                :open-on-click="!$vuetify.breakpoint.mdAndUp"
                :open-on-hover="$vuetify.breakpoint.mdAndUp"
                class-content="gst-checkout-action__tooltip"
                class-activator="gst-checkout-action__tooltip-activator ml-4 ml-md-2">
                <HelpIcon />
            </BaseTooltip>
        </div>
        <div class="gst-checkout-terms__checkboxes__item d-flex mb-2">
            <BaseFormCheckbox
                v-if="showEventHealthCheck"
                id="termsEventHealthCheck"
                v-model="agreeModel.termsEventHealthCheck"
                :data-test-id="'termsEventHealthCheck'">
                <template slot="label">
                    {{ $t( 'labels.termsEventHealthCheck' ) }}
                    <a :href="eventHealthCheckUrl" target="_blank" class="d-inline">
                        {{ $t('_common:buttons.learnMore') }}
                    </a>
                </template>
            </BaseFormCheckbox>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip';
    import BaseFormCheckbox from '@tenant/app/components/inputs/BaseFormCheckbox';
    import HelpIcon from '@tenant/app/assets/icons/help.svg';

    export default {
        name: 'CheckoutTermsCheckboxes',
        components: {
            BaseFormCheckbox,
            BaseTooltip,
            HelpIcon
        },
        props: {
            items: {
                type: Array,
                default: () => []
            },
            agreeModel: {
                type: Object,
                required: true
            },
            showEventHealthCheck: {
                type: Boolean,
                default: false
            },
            eventHealthCheck: {
                type: Object,
                default: () => ( { } )
            },
        },
        computed: {
            eventHealthCheckUrl() {
                return this.eventHealthCheck?.learnMoreUrl || '';
            },
        },
        methods: {
            initCheckboxesModel() {
                // remove fallback items from agreeModel
                Vue.delete( this.agreeModel, 'termsEventTicket' );
                Vue.delete( this.agreeModel, 'termsPrivacyPolicy' );

                // add new items
                ( this.items || [] ).forEach( ( item, index ) => {
                    Vue.set( this.agreeModel, `terms_${index}`, false );
                } );
            }
        },
        created() {
            this.initCheckboxesModel();
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    ::v-deep .gst-base-form-checkbox .gst-base-form-checkbox__label p,
    ::v-deep .gst-base-form-checkbox .gst-base-form-checkbox__label ul,
    ::v-deep .gst-base-form-checkbox .gst-base-form-checkbox__label ol {
        margin-bottom: theme-spacing( 2 );
    }

    ::v-deep .gst-base-form-checkbox .gst-base-form-checkbox__checkmark {
        top: 2px;
        transform: none;
    }

</style>