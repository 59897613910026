<template>
    <div>
        <template v-if="Boolean( texts )">
            <slot :texts="texts"></slot>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { LogService } from '@core/services';
    import CartPurchaseFlowMixin from '@tenant/app/mixins/CartPurchaseFlow';

    export default {
        name: 'CheckoutTermsLoader',
        mixins: [ CartPurchaseFlowMixin ],
        emits: [ 'has-content' ],
        props: {
            countryCode: {
                type: String,
                required: true
            },
            legacyEventId: {
                type: String,
                default: null
            }
        },
        data( ) {
            return {
                content: null,
                texts: null,
                hasContent: false
            };
        },
        computed: {
            ...mapGetters( {
                appLocale: 'appState/getLocale'
            } ),
            prefix( ) {
                if ( this.isCartDynamicFlow && this.content?.data.dynamic__overwrite ) {
                    return 'dynamic__';
                }
                if ( this.isCartPackagesFlow && this.content?.data.packages__overwrite ) {
                    return 'packages__';
                }
                if ( this.isCartExperiencesFlow && this.content?.data.experiences__overwrite ) {
                    return 'experiences__';
                }
                if ( this.isCartPreAllocatedFlow && this.content?.data.hotels_pre_allocated__overwrite ) {
                    return 'hotels_pre_allocated__';
                }
                if ( this.isCartOpenInventoryFlow && this.content?.data.hotels_open_inventory__overwrite ) {
                    return 'hotels_open_inventory__';
                }
                return '';
            }
        },
        methods: {
            initData( ) {
                this.texts = {
                    taxesDisclaimer: this.content.data[`${this.prefix}text_above`],
                    cancellationDisclaimer: this.content.data[`${this.prefix}text_under`],
                    eventCancellationPolicy: this.content.data[`${this.prefix}event_cancellation_policy`],
                    terms: ( this.content.data[`${this.prefix}terms`] || [] ).map( item => {
                        return {
                            label: item.label,
                            tooltip: this.$prismic.asText( item.tooltip )
                        };
                    } )
                };
                this.hasContent = true;
            },
            async initForEvent( ) {
                this.content = await this.getContentForEvent( this.appLocale );
                if ( !this.content ) {
                    //fallback to master language
                    this.content = await this.getContentForEvent( );
                }
                if ( this.content ) {
                    this.initData( );
                } else {
                    await this.initForCountry( );
                }
            },
            async getContentForEvent( lang ) {
                const config = lang ? { lang } : { };

                try {
                    return await this.$prismic.client.getByUID(
                        'event-legal-info-checkout',
                        this.legacyEventId.toLocaleLowerCase( ),
                        config
                    );
                } catch( e ) {
                    LogService.log( e.message );
                }
            },
            async initForCountry( ) {
                this.content = await this.getContent( this.appLocale );
                if ( !this.content ) {
                    //fallback to master language
                    this.content = await this.getContent( );
                }
                if ( this.content ) {
                    this.initData( );
                }
            },
            async getContent( lang ) {
                const config = lang ? { lang } : { };

                try {
                    return await this.$prismic.client.getByUID(
                        'country-legal-info-checkout',
                        this.countryCode.toLocaleLowerCase( ),
                        config
                    );
                } catch( e ) {
                    LogService.log( e.message );
                }
            },
            
        },
        async created( ) {
            if ( this.legacyEventId ) {
                await this.initForEvent( );
            } else {
                await this.initForCountry( );
            }
            this.$emit( 'has-content', this.hasContent );
        }
    };
</script>