<template>
    <div class="gst-fees-container">
        <div class="mt-2 mt-md-4">
            <h5>{{ title }}</h5>
            <div v-if="!hasHotelReservationOnly" class="d-flex flex-row">
                <div class="gst-fees-container__item u-text-transform-by-language">
                    <div v-for="(item, key) in itemsShow" :key="key" class="mb-0">
                        <span class="mr-1">{{ item.name }}</span>
                        <span>x {{ item.quantity }}</span>
                    </div>
                </div>
                <div class="gst-fees-container__price">
                    {{ totalBasePrice | currencyFilter( currency ) }}
                </div>
            </div>
            <div v-else class="d-flex flex-row">
                <div class="gst-fees-container__item u-text-transform-by-language">
                    <div class="mb-0">
                        <span class="mr-1">{{ hotelReservationRoomName }}</span>
                        <span class="mr-1">x {{ hotelReservationRoomQuantity }}</span>
                    </div>
                </div>
                <div class="gst-fees-container__price">
                    {{ totalBasePrice | currencyFilter( currency ) }}
                </div>
            </div>
            <div v-if="feesHotelsIncludedAmount" class="gst-fees-container__row-variant-2 mt-2">
                <div class="gst-fees-container__item">
                    <span>{{ $t( '_common:terms.feesIncluded' ) }}</span>
                </div>
                <div class="gst-fees-container__price">
                    {{ feesHotelsIncludedAmount | currencyFilter( currency ) }}
                </div>
            </div>
        </div>
        <div class="mt-4 mt-md-6">
            <h5>{{ $t( '_common:terms.fee_plural' ) }}</h5>
            <div v-if="hasFees">
                <div v-if="fees.length" class="gst-fees-container__row">
                    <div v-for="(item, key) in fees" :key="key" class="d-flex flex-row flex-grow-1">
                        <div class="gst-fees-container__item u-text-transform-by-language">
                            <div v-if="!item.isOrderFee || item.isOrderFee && item.quantity > 1">
                                <span class="mr-1">{{ item.name }}:</span>
                                <span class="mr-1">{{ item.price | currencyFilter( currency ) }}</span>
                                <span>x {{ item.quantity }}</span>
                            </div>
                            <div v-else>
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="gst-fees-container__price">
                            {{ item.amount | currencyFilter( item.currency ) }}
                        </div>
                    </div>
                </div>
                <div v-if="feesHotelsNotIncludedAmount" class="gst-fees-container__row-variant-2 mt-1 mt-md-4">
                    <div class="gst-fees-container__item">
                        <span>{{ $t( '_common:terms.feesNotIncluded' ) }}</span>
                        <BaseTooltip
                            bottom
                            :text="feesHotelsNotIncludedTooltipContent"
                            class-content="gst-fees-container__tooltip-content"
                            class="gst-fees-container__tooltip">
                            <HelpIcon />
                        </BaseTooltip>
                    </div>
                    <div class="gst-fees-container__price">
                        {{ feesHotelsNotIncludedAmount | currencyFilter( currency ) }}
                    </div>
                </div>
            </div>
            <div v-else>
                ---
            </div>
        </div>
        <div v-if="shippingOption && !hasHotelReservationOnly" class="mt-4 mt-md-6">
            <h5>{{ $t( '_common:terms.delivery' ) }}</h5>
            <div class="d-flex flex-row mt-2">
                <div class="gst-fees-container__item">
                    {{ shippingOption.description.short }}
                </div>
                <div class="gst-fees-container__price">
                    {{ shippingOption.totals.fee | currencyFilter( shippingOption.totals.currencyCode ) }}
                </div>
            </div>
        </div>
        <BaseButton
            color="primary"
            text
            link
            :data-test-id="$testId('button.cancelOrder')"
            class="gst-fees-container__cancel-btn"
            @click="$emit('cancel')">
            {{ $t( '_common:buttons.cancelOrder' ) }}
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import productsConstants from '@core/utils/constants/products';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import {
        findOneNonElectronicTicketItem as shippingOptionsUtilsFindOneNonElectronicTicketItem,
        findOneItemWithFees as shippingOptionsUtilsFindOneItemWithFees
    } from '@core/utils/shippingOptionsUtils';
    import HelpIcon from '@tenant/app/assets/icons/help.svg';
    import FeesContainerMixin from '../Mixins/FeesContainerMixin';

    export default {
        name: 'FeesContainer',
        components: {
            BaseButton,
            BaseTooltip,
            HelpIcon
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckout._components.containers.feesContainer'
        },
        testIdOptions: {
            keyPrefix: 'checkout.containers.feesContainer'
        },
        mixins: [ FeesContainerMixin ],
        props: {
            cartCollections: {
                type: Array,
                required: true
            },
            order: {
                type: Object,
                required: true
            },
            hasHotelReservationOnly: {
                type: Boolean,
                default: false
            },
            shippingModel: {
                type: Object,
                required: true
            },
        },
        computed: {
            title: function( ) {
                if ( this.hasHotelReservationOnly ) {
                    return this.$t( '_common:terms.hotel' );
                }

                return this.$t( '_common:terms.bundleTicketsHotel' );
            },
            totalBasePrice( ) {
                return this.order.basePrice;
            },
            currency( ) {
                return this.order.currency;
            },
            ticketItems( ) {
                return this.ticket.items;
            },
            hotelReservation( ) {
                return this.cartCollections
                    .filter( item => item.productTypeId === productsConstants.TYPES.HOTEL_RESERVATION ) [ 0 ];
            },
            hotelReservationRoomName( ) {
                return this.hotelReservation.hotel.room.name;
            },
            hotelReservationRoomQuantity( ) {
                return this.hotelReservation.accommodation.roomsCount;
            },
            itemsShow( ) {
                const { ticketItems, hotelReservation } = this;

                const ret = ticketItems.reduce( ( acc, item ) => {
                    acc = [
                        ...acc,
                        {
                            name: item.name,
                            quantity: item.quantity
                        }
                    ];

                    return acc;
                }, [] );

                ret.push( {
                    name: hotelReservation.hotel.room.name.toLowerCase(),
                    quantity: hotelReservation.accommodation.roomsCount
                } );

                return ret;
            },
            showShippingFeesSection( ) {
                const { options } = this.shippingModel;

                return shippingOptionsUtilsFindOneNonElectronicTicketItem( options )
                    || shippingOptionsUtilsFindOneItemWithFees( options );
            }
        }
    };
</script>


<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-fees-container {
        color: theme-color( 'quaternary' );

        h5 {
            line-height: line-height( 'xxxl' );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'large' );
            text-transform: capitalize;
        }

        .gst-fees-container__item,
        .gst-fees-container__price {
            line-height: line-height( 'xl' );
            font-size: font-size( 's' );
            font-weight: font-weight( 'regular' );
        }

        .gst-fees-container__item {
            flex-grow: 1;
        }

        .gst-fees-container__price {
            flex-shrink: 0;
        }

        .gst-fees-container__cancel-btn {
            padding: theme-spacing( 6, 0, 6, 0 );
            font-weight: font-weight( 'large' );
            letter-spacing: 0;
            text-transform: capitalize;
        }

        .gst-fees-container__row {
            display: flex;
            flex-direction: column;
        }

        .gst-fees-container__row-variant-2 {
            display: flex;
            color: theme-color( 'tertiary' );

            .gst-fees-container__item {
                display: flex;
                line-height: line-height( 'm' );
                padding-right: theme-spacing( 2 );
                font-size: font-size( 'xxs' );
                flex-direction: row;

                span {
                    margin-right: theme-spacing( 4 );
                }

                svg {
                    height: 18px;
                    width: 18px;

                    .gst-svg-icon {
                        stroke: theme-color( 'tertiary' ) !important;
                        transition: all 0.2s ease-in;
                    }

                    .gst-svg-icon:nth-child(2) {
                        fill: transparent !important;
                    }
                }
            }

            .gst-fees-container__price {
                line-height: line-height( 'm' );
                font-size: font-size( 'xxs' );
            }
        }

        @include mobile-only {
            .gst-fees-container__item,
            .gst-fees-container__price {
                line-height: line-height( 'xxxl' );
                font-size: font-size( 'xs' );
            }
        }
    }
</style>
<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-fees-container__tooltip-content.v-tooltip__content {
        @include border-radius( 'xs' );

        width: 316px !important;
        padding: theme-spacing( 4 ) !important;
        background: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' ) !important;
        box-shadow: 0 2px 6px rgba( 0, 0, 0, 0.25 ) !important;
        opacity: 1 !important;
    }
</style>
