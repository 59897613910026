<template>
    <div class="gst-checkout-action d-flex flex-column">
        <header class="d-flex flex-row justify-center">
            <p class="flex-grow-1">
                {{ title }}
            </p>
            <p>
                {{ totalPrice | currencyFilter( order.currency ) }}
            </p>
            <div
                class="u-mouse-pointer"
                :data-test-id="$testId('button.toggleDetails')"
                tabindex="0"
                @click="showFees = !showFees"
                @keypress.enter="showFees = !showFees">
                <ChevronDownIcon
                    class="gst-checkout-action__down-icon"
                    :class="{
                        'u-rotate-180': showFees
                    }" />
            </div>
        </header>
        <v-expand-transition>
            <FeesContainer
                v-if="showFees"
                :order="order"
                :cart-collections="cartCollections"
                :shipping-model="shippingModel"
                :has-hotel-reservation-only="hasHotelReservationOnly"
                @cancel="$emit('cancel')" />
        </v-expand-transition>
        <div class="gst-checkout-action__content">
            <CheckoutTermsLoader
                :country-code="cartPurchaseCountry"
                :legacy-event-id="legacyEventId"
                @has-content="onCheckoutTermsContentDo">
                <template slot-scope="{ texts }">
                    <PrismicRichText
                        v-if="texts.taxesDisclaimer"
                        :field="texts.taxesDisclaimer" 
                        class="gst-checkout-terms__taxes-disclaimer mb-6" />
                    <CheckoutTermsCheckboxes
                        :items="texts.terms"
                        :agree-model="agreeModel"
                        :show-event-health-check="showTermsEventHealthCheck"
                        :event-health-check="eventHealthCheck" />
                    <BaseButton
                        color="success"
                        :data-test-id="$testId('button.placeOrder')"
                        class="u-width-100 mb-4"
                        :disabled="isPlaceOrderDisabled"
                        @click="onClickButtonDo">
                        {{ $t( '_common:buttons.placeOrder' ) }}
                    </BaseButton>
                    <p v-if="!isCartOpenInventoryFlow && $prismic.asText(texts.eventCancellationPolicy)" class="gst-checkout-action__warning">
                        <a href="#" @click="openEventCancellationPolicyModal($event, texts.eventCancellationPolicy)">{{ $t( '_common:terms.eventCancellationPolicy' ) }}</a>
                    </p>
                    <PrismicRichText
                        v-if="texts.cancellationDisclaimer"
                        :field="texts.cancellationDisclaimer" 
                        class="gst-checkout-terms__taxes-disclaimer" />
                </template>
            </CheckoutTermsLoader>
            <template v-if="!showPrismicTerms">
                <label v-if="isPackageOffer" for="agreeModel.termsEventTicket" class="d-block">{{ $t('allSalesFinal') }}</label>
                <label v-if="isPackageOffer && cartPurchaseCountry !== 'AU'" for="agreeModel.termsEventTicket" class="d-block mt-2">{{ $t('allTaxesAndFeesIncluded') }}</label>
                <label v-if="isPackageOffer"
                    for="agreeModel.termsEventTicket"
                    class="d-block gst-checkout-action__content__travel-packages mt-1"
                    :class="$vuetify.breakpoint.mdAndUp ? 'mb-4' : 'mb-6'">
                    {{ $t('allTravelPackages') }}</label>
                <label v-if="hasHotelReservationOnly" for="agreeModel.termsEventTicket" class="d-block mb-6">{{ $t( 'allHotelTaxesAndFeesIncluded' ) }}</label>
                <BaseFormCheckbox
                    id="agreeModel.termsEventTicket"
                    v-model="agreeModel.termsEventTicket"
                    :error-messages="extractValidationsMessagesFn( 'agreeModel' )"
                    :data-test-id="$testId('checkbox.termsEventTicket')"
                    class="mb-2">
                    <template slot="label">
                        <TermsLabel :is-package-offer="isPackageOffer" :purchase-flow="cartPurchaseFlow" :country="cartPurchaseCountry" :language="appStateLanguage" />
                    </template>
                </BaseFormCheckbox>
                <div class="d-flex mb-2">
                    <BaseFormCheckbox
                        id="agreeModel.termsPrivacyPolicy"
                        v-model="agreeModel.termsPrivacyPolicy"
                        :error-messages="extractValidationsMessagesFn( 'agreeModel' )"
                        :data-test-id="$testId('checkbox.termsPrivacyPolicy')">
                        <template slot="label">
                            {{ $t( 'labels.termsPrivacyPolicy' ) }}
                            <BaseUrlLink :country="cartPurchaseCountry" :language="appStateLanguage" url-link-key="privacyPolicy" i18n-key="_common:linksLabels.privacyPolicy" data-test-id="button.termsPrivacyPolicy" />
                        </template>
                    </BaseFormCheckbox>
                    <BaseTooltip
                        :text="$t('privacyPolicyTooltip')"
                        :open-on-click="!$vuetify.breakpoint.mdAndUp"
                        :open-on-hover="$vuetify.breakpoint.mdAndUp"
                        class-content="gst-checkout-action__tooltip"
                        class-activator="gst-checkout-action__tooltip-activator ml-4 ml-md-2">
                        <HelpIcon />
                    </BaseTooltip>
                </div>
                <BaseFormCheckbox
                    v-if="showTermsEventHealthCheck"
                    id="agreeModel.termsEventHealthCheck"
                    v-model="agreeModel.termsEventHealthCheck"
                    :error-messages="extractValidationsMessagesFn( 'agreeModel' )"
                    :data-test-id="$testId('checkbox.termsEventHealthCheck')"
                    class="mb-2">
                    <template slot="label">
                        {{ $t( 'labels.termsEventHealthCheck' ) }}
                        <a :href="eventHealthCheckUrl" target="_blank" class="d-inline">
                            {{ $t('_common:buttons.learnMore') }}
                        </a>
                    </template>
                </BaseFormCheckbox>
                <BaseButton
                    color="success"
                    :data-test-id="$testId('button.placeOrder')"
                    class="u-width-100 mb-4"
                    :disabled="isPlaceOrderDisabled"
                    @click="onClickButtonDo">
                    {{ $t( '_common:buttons.placeOrder' ) }}
                </BaseButton>
                <p v-if="!isCartOpenInventoryFlow" class="gst-checkout-action__warning">
                    <a href="#" @click="openEventCancellationPolicyModal">{{ $t( '_common:terms.eventCancellationPolicy' ) }}</a>
                </p>
                <p v-if="isCartOpenInventoryFlow" class="gst-checkout-action__warning">
                    {{ $t( 'exceptionsOpenInventory' ) }}
                </p>
                <p v-else-if="isCartDynamicFlow" class="gst-checkout-action__warning">
                    {{ `*${$t('exceptionsDynamic')}` }}
                </p>
                <p v-else class="gst-checkout-action__warning">
                    {{ `*${$t('exceptionsApplying')}` }}
                </p>
            </template>
            <p v-if="hasFeesHotelsNotIncluded" class="gst-checkout-action__warning">
                {{ $t('feesNotIncluded') }}
            </p>
        </div>
        <HotelCancellationPolicy
            v-if="!isPackageOffer && room.cancellationPolicies"
            :cancellation-policies="room.cancellationPolicies"
            :currency="currency"
            :base-price="hotel.price.basePrice"
            :accomodation-nights="accommodation.nights" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import ChevronDownIcon from '@core/shared/assets/icons/chevron_down.svg';
    import roomRateFeeConstants from '@core/utils/constants/roomRateFee';
    import productsConstants from '@core/utils/constants/products';
    import BaseFormCheckbox from '@tenant/app/components/inputs/BaseFormCheckbox';
    import HelpIcon from '@tenant/app/assets/icons/help.svg';
    import HotelCancellationPolicy from '@tenants/ticketmaster/app/views/cart/TheCheckout/HotelCancellationPolicy.vue';
    import BaseUrlLink from '@tenants/ticketmaster/app/components/links/BaseUrlLink.vue';
    import CartPurchaseFlowMixin from '@tenant/app/mixins/CartPurchaseFlow';
    import FeesContainer from './Containers/FeesContainer.vue';
    import TermsLabel from './Action/TermsLabel';
    import CheckoutTermsLoader from './Action/CheckoutTermsLoader';
    import CheckoutTermsCheckboxes from './Action/CheckoutTermsCheckboxes';

    export default {
        name: 'Action',
        components: {
            BaseButton,
            FeesContainer,
            BaseFormCheckbox,
            ChevronDownIcon,
            HotelCancellationPolicy,
            BaseTooltip,
            HelpIcon,
            TermsLabel,
            BaseUrlLink,
            CheckoutTermsLoader,
            CheckoutTermsCheckboxes
        },
        mixins: [ CartPurchaseFlowMixin ],
        props: {
            accommodation: {
                type: Object,
                required: true
            },
            agreeModel: {
                type: Object,
                required: true
            },
            order: {
                type: Object,
                required: true
            },
            cartCollections: {
                type: Array,
                required: true
            },
            currency: {
                type: String,
                required: true
            },
            shippingModel: {
                type: Object,
                required: true
            },
            validation: {
                type: Object,
                required: true
            },
            extractValidationsMessagesFn: {
                type: Function,
                required: true,
            },
            totalPrice: {
                type: Number,
                required: true
            },
            eventHealthCheck: {
                type: Object,
                required: false,
                default: () => { return { }; }
            },
            isPaymentProviderDown: {
                type: Boolean,
                default: false
            },
            isPackageOffer: {
                type: Boolean,
                default: false
            },
            cartHasTicket: {
                type: Boolean,
                default: false
            },
            hasHotelReservationOnly: {
                type: Boolean,
                required: false
            },
            room: {
                type: Object,
                required: true
            }
        },
        data( ) {
            return {
                showFees: false,
                showPrismicTerms: true
            };
        },
        computed: {
            ...mapGetters( {
                cartPurchaseCountry: 'cart/purchaseCountry',
                appStateLanguage: 'appState/getLanguage'
            } ),
            eventHealthCheckUrl() {
                return this.eventHealthCheck ? this.eventHealthCheck.learnMoreUrl : '';
            },
            showTermsEventHealthCheck() {
                return !!this.agreeModel.hasOwnProperty( 'termsEventHealthCheck' ) && !this.hasHotelReservationOnly;
            },
            title( ) {
                if( this.hasHotelReservationOnly ) {
                    return this.$t( 'titleHotelReservationOnly' );
                }

                return this.$t( 'title' );
            },
            hotel() {
                const items = this.cartCollections;

                return items.find( item => item.productTypeId === productsConstants.TYPES.HOTEL_RESERVATION );
            },
            event( ) {
                const items = this.cartCollections;

                return items.find( item => item.productTypeId === productsConstants.TYPES.TICKET );
            },
            legacyEventId( ) {
                return this.event?.legacyEventId || null;
            },
            hasFeesHotelsNotIncluded( ) {
                const fees = this.hotel ? this.hotel.charges : [];

                return fees.length ? !!fees.filter( item => item.included === roomRateFeeConstants.TYPES_INCLUDED.PAY_LATER ).length : false;
            },
            hasAgreedTerms( ) {
                return Object.values( this.agreeModel ).every( term => term );
            },
            isPlaceOrderDisabled( ) {
                return this.isPaymentProviderDown || !this.hasAgreedTerms;
            },
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckout._components.action'
        },
        testIdOptions: {
            keyPrefix: 'checkout._components.action'
        },
        methods: {
            onCheckoutTermsContentDo( hasContent ) {
                this.showPrismicTerms = hasContent;
            },
            openEventCancellationPolicyModal( event, modalContent ) {
                event.preventDefault();
                const propsMobile = {
                    'content-class': 'v-dialog__height-50 gst-v-dialog',
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition',
                };
                const propsDesktop = {
                    'no-click-animation': true,
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    'max-width': 600,
                    'min-height': 200,
                    'content-class': 'gst-v-dialog gst-v-dialog-event-cancellation-policy',
                };

                if ( this.$vuetify.breakpoint.mdAndUp  ){
                    this.$modal.show(
                        ( ) => import( '@tenant/app/views/cart/TheCheckout/EventCancellationPolicyModal' ),
                        {
                            isPackageOffer: this.isPackageOffer,
                            modalContent
                        },
                        propsDesktop
                    );
                } else {
                    this.$modal.showBottom(
                        ( ) => import( '@tenant/app/views/cart/TheCheckout/EventCancellationPolicyModal' ),
                        {
                            isPackageOffer: this.isPackageOffer,
                            modalContent
                        },
                        propsMobile
                    );
                }
            },
            onClickButtonDo() {
                this.$emit( 'submit' );
            }
        },
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-checkout-action {
        @include border-radius('xs');

        padding: theme-spacing( 4, 4, 4, 3 );
        border: 1px solid theme-color( 'septenary' );

        header {
            p {
                line-height: line-height( 'xxxxl' );
                font-size: font-size( 'xxxl' );
                font-weight: font-weight( 'large' );
            }

            .gst-checkout-action__down-icon {
                transition: 0.2s;

                .gst-svg-icon {
                    fill: theme-color( 'tertiary' );
                }
            }
        }

        .gst-checkout-action__content {
            line-height: line-height( 'xl' );
            color: theme-color( 'quaternary' );
            font-size: font-size( 's' );
            font-weight: font-weight( 'large' );

            .gst-checkout-action__content__travel-packages {
                font-weight: font-weight( 'regular' );
            }
        }

        .gst-checkout-terms__taxes-disclaimer {
            font-weight: font-weight( 'regular' );

            p {
                margin: theme-spacing( 1, 0 );
            }

            b,
            strong {
                font-weight: font-weight( 'large' );
            }
        }

        .gst-checkout-action__warning {
            line-height: line-height( 'm' );
            margin-bottom: theme-spacing( 2 ) !important;
            color: theme-color( 'quaternary' );
            font-size: font-size( 's' );
            font-weight: font-weight( 'large' );
        }

        .gst-checkout-action__tooltip-activator {
            display: inline-block;
            vertical-align: text-top;

            .gst-svg-icon {
                stroke: theme-color( 'tertiary' ) !important;
            }
        }

        .gst-checkout-action__tooltip-activator:hover {
            .gst-svg-icon {
                stroke: theme-color( 'primary' ) !important;
            }
        }

        @include mobile-only {
            .gst-checkout-action__content {
                ::v-deep .gst-base-form-checkbox {
                    .gst-base-form-checkbox__label {
                        padding-left: theme-spacing( 10 );
                    }
                }
            }
        }
    }

    .gst-checkout-action__tooltip.v-tooltip__content {
        line-height: font-size( 'l' );
        padding: theme-spacing( 4 );
        background: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' );
        font-size: font-size( 's' );
        max-width: 350px;
        box-shadow: 0 2px 6px rgba( theme-color-hex( 'black' ), 0.25 );
        opacity: 1 !important;
    }
</style>
