import apiConstants from '@core/utils/constants/api';
import { createApiService, fetchAPI } from '@core/api/_utils';
import transformPaymentGetClientToken from '@core/data/request/transformPaymentGetClientToken';

let api = {
    payments: {
        getClientToken: ( cartId, paymentMethod ) => {
            return fetchAPI.get(
                `${apiConstants.BASE_URL}/api/client/token`,
                transformPaymentGetClientToken( { cartId, paymentMethod } )
            );
        },
        calculateAmountRemainingFromTotal: ( totalPrice, usedPoints, fullRedeemPoints ) => {
            return fetchAPI.post(
                `${apiConstants.BASE_URL}/api/payments/points`,
                {
                    totalPrice: totalPrice,
                    fullRedeemPoints: fullRedeemPoints,
                    points: usedPoints
                }
            );
        }
    }
};

export default async function ( config ) {
    return createApiService( config, api );
}
