<template>
    <div class="gst-payment-fieldset-hotels-and-tickets">
        <div class="header">
            <span class="title">{{ $t('title') }}</span>
        </div>
        <BaseIcon
            v-for="logo in providersLogos"
            :key="logo"
            :symbol-id="logo" 
            class="providers-logos" />
        <PaymentWidget
            ref="paymentWidget"
            class="col col-12 py-0"
            :token="paymentClientModel.token"
            :type="paymentClientModel.providerId"
            @open-authorization="$emit( 'open-client-payment-authorization')"
            @close-authorization="$emit( 'close-client-payment-authorization')"
            @load-client-failed="$emit( 'load-client-payment-failed', $event )"
            @update-payment-fields-validity="onUpdatePaymentFieldsValidityDo" />
        <div class="line-wrapper">
            <BaseFormSelect
                id="customerModel.country"
                v-model="customerModel.country"
                :has-error="countrySelectorHasError"
                :options="countries"
                value-prop="code"
                :label="$t('labels.country')"
                :error-messages="extractValidationsMessagesFn( 'customerModel.country' )"
                class="gst-base-form-select-variant col col-12 pt-0"
                :data-test-id="$testId('select.country')"
                @blur="validation.customerModel.country.$touch()" />
        </div>
        <div class="line-wrapper">
            <BaseFormInput
                id="customerModel.address"
                v-model="customerModel.address"
                :label="$t('labels.address')"
                :error-messages="extractValidationsMessagesFn( 'customerModel.address' )"
                :show-check-icon="validation.customerModel.address.$dirty"
                maxlength="100"
                class="gst-base-form-select-variant col col-12 pt-0"
                :data-test-id="$testId('input.address')"
                @blur="validation.customerModel.address.$touch()" />
        </div>
        <div class="line-wrapper">
            <BaseFormInput
                id="customerModel.city"
                v-model="customerModel.city"
                :label="$t('labels.city')"
                :error-messages="extractValidationsMessagesFn( 'customerModel.address' )"
                :show-check-icon="validation.customerModel.city.$dirty"
                maxlength="100"
                class="col col-12 col-md-6 pt-0"
                :data-test-id="$testId('input.city')"
                @blur="validation.customerModel.city.$touch()" />
            <BaseFormSelect
                id="customerModel.state"
                v-model="customerModel.state"
                :disabled="!states.length"
                :has-error="stateSelectorHasError"
                :options="states"
                value-prop="code"
                :label="$t('labels.state')"
                :error-messages="extractValidationsMessagesFn( 'customerModel.state' )"
                :data-test-id="$testId('select.state')"
                class="gst-base-form-select-variant col col-12 col-md-6 pt-0"
                :class="{'gst-base-form-select-variant__disabled' : disabledStateSelect}"
                @blur="validation.customerModel.state.$touch()" />
        </div>
        <div class="line-wrapper">
            <BaseFormInput
                id="customerModel.zip"
                v-model="customerModel.zip"
                :label="$t('labels.zip')"
                :error-messages="extractValidationsMessagesFn( 'customerModel.zip' )"
                :show-check-icon="validation.customerModel.zip.$dirty"
                maxlength="10"
                class="col col-12 pt-0"
                :data-test-id="$testId('input.zip')"
                @blur="validation.customerModel.zip.$touch()" />
            <BaseFormInput
                id="customerModel.phone"
                v-model="customerModel.phone"
                :label="$t('labels.phone')"
                :error-messages="extractValidationsMessagesFn( 'customerModel.phone' )"
                :show-check-icon="validation.customerModel.phone.$dirty"
                maxlength="30"
                class="col col-12 col-md-6 pt-0"
                :data-test-id="$testId('input.phone')"
                @blur="validation.customerModel.phone.$touch()" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseFormInput from '@tenants/ticketmaster/app/components/inputs/BaseFormInput.vue';
    import BaseFormSelect from '@tenants/ticketmaster/app/components/inputs/BaseFormSelect.vue';
    import PaymentWidget from '@tenants/ticketmaster/app/components/payment/PaymentWidget.vue';

    export default {
        name: 'PaymentFieldsetHotelAndTickets',
        components: {
            BaseFormInput,
            BaseFormSelect,
            BaseIcon,
            PaymentWidget
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckout._components.fieldsets.paymentFieldset'
        },
        testIdOptions: {
            keyPrefix: 'checkout.fieldsets.paymentFieldset'
        },
        props: {
            customerModel: {
                type: Object,
                required: true,
            },
            validation: {
                type: Object,
                required: true
            },
            extractValidationsMessagesFn: {
                type: Function,
                required: true,
            },
            paymentClientModel: {
                type: Object,
                required: true,
            }
        },
        emits:[
            'changeRequiredField' //for validation
        ],
        data: function() {
            return {
                countryCode: null,
                stateCode: null,
                states: [],
            };
        },
        computed: {
            ...mapState( {
                language: state => state.appState.language,
                countries: state => state.countries.list
            } ),
            ...mapGetters( {
                cartPurchaseCountry: 'cart/purchaseCountry',
            } ),
            providersLogos() {
                if ( [ 'GB', 'UK' ].includes( this.cartPurchaseCountry ) ) {
                    return [ 'Visa-logo', 'mastercard-logo' ];
                } else {
                    return [
                    'Visa-logo',
                    'mastercard-logo',
                    'discover-logo',
                    'diners-club-logo',
                    'americanexpress-logo'
                    ];
                }
            },
            stateSelectorHasError() {
                return this.validation.customerModel.state.$model ? this.validation.customerModel.state.$invalid : this.validation.customerModel.state.$dirty;
            },
            countrySelectorHasError() {
                return this.validation.customerModel.country.$model ? this.validation.customerModel.country.$invalid : this.validation.customerModel.country.$dirty;
            },
            disabledStateSelect() {
                return this.customerModel.country !== 'US' && this.customerModel.country !== 'CA';
            }
        },
        watch: {
            'customerModel.country': {
                handler: function( value ) {
                    if ( value ) {
                        this.loadStates();
                    }
                },
                immediate: true
            },
            'states.length': {
                handler: function ( value ) {
                    this.$emit( 'changeRequiredField', { field: 'state', value: !!value } );
                },
                immediate: true
            }
        },
        methods: {
            stateFilters() {
                if ( this.customerModel.country ) {
                    return {
                        code: this.customerModel.country
                    };
                }
                return null;
            },
            async loadStates() {
                const { success, data } = await this.$store.dispatch( `states/getRaw`, { filter: this.stateFilters() }  );
                if ( success ) {
                    this.states = [ ...data.list ];
                } else {
                    this.states = [ ];
                }
            },
            onUpdatePaymentFieldsValidityDo( validPaymentFields ) {
                this.$emit( 'updatePaymentFieldsValidity', validPaymentFields );
            },
            onInstancePaymentClientComputedDo( instancePaymentClient ) {
                this.$emit( 'instancePaymentClientComputed', instancePaymentClient );
            }
        },
        async mounted( ) {
            await this.loadStates();
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-payment-fieldset-hotels-and-tickets {
    .line-wrapper {
        .gst-base-form-input,
        .gst-base-form-select {
            align-self: flex-end;
        }

        .gst-base-form-select-variant__disabled {
            select {
                background-color: theme-color( 'senary' );
            }

            svg {
                .gst-svg-icon {
                    fill: theme-color( 'septenary' );
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    #hotelAndTicketsPaymentClient {
        margin-left: theme-spacing( 'n1' );
    }

    .gst-payment-fieldset-hotels-and-tickets {
        @include border-radius( 'xs' );

        padding: theme-spacing( 4 ) theme-spacing( 6 );
        border: 1px solid theme-color( 'septenary' );
        margin-top: theme-spacing( 8 );

        .credit-card-row {
            display: flex;
            align-items: center;

            .inputs-container {
                display: flex;
                position: relative;
                bottom: theme-spacing( 1 );
                align-items: center;
                justify-content: flex-start;

                .expiration-date-input,
                .cvv-code-input {
                    max-width: 200px;
                }
            }

            .info-container {
                display: flex;
                position: relative;
                bottom: 2px;
                align-items: center;
                margin-left: theme-spacing( 1 );

                .cvv-img {
                    position: relative;
                    top: 2px;
                }

                .cvv-info {
                    position: relative;
                    top: 2px;
                    line-height: line-height( 'm' );
                    color: theme-color( 'tertiary' );
                    font-size: font-size( 'xxs' );
                    margin-left: theme-spacing( 2 );
                }
            }

            @include mobile-only {
                flex-direction: column;

                .inputs-container {
                    justify-content: space-between;

                    .gst-base-form-input {
                        flex: 1;
                    }
                }

                .info-container {
                    display: flex;
                    position: relative !important;
                    right: theme-spacing( 4 );
                    bottom: theme-spacing( 1 ) !important;
                    width: 100%;
                    justify-content: flex-end;
                }
            }
        }

        .providers-logos {
            margin: theme-spacing( 2 ) 0 theme-spacing( 3 ) theme-spacing( 3 );
           
            @include mobile-only {
                margin-top: 0 !important;
                margin-left: theme-spacing( 3 );
            }
        }

        .line-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .gst-base-form-input {
                flex: 1;
            }
        }

        .line-wrapper:last-of-type {
            margin-bottom: 6px;
        }

        .header {
            display: flex;
            padding: theme-spacing( 0 ) theme-spacing( 3 );
            margin-bottom: theme-spacing( 4 );
            font-family: "Averta Std", sans-serif !important;
            align-items: center;
            justify-content: space-between;

            .title {
                line-height: line-height( 'xxxxl' );
                color: theme-color( 'quaternary' );
                font-size: font-size( 'xxxl' );
                font-weight: font-weight( 'large' );
                font-family: "Averta Std", sans-serif !important;
            }

            .officials {
                span {
                    line-height: line-height( 'xxs' );
                    color: theme-color( 'tertiary' );
                    font-size: font-size( 'xxxxs' );
                    font-weight: normal;
                    text-transform: uppercase;
                }

                .ticketmaster-logo {
                    position: relative;
                    top: 2px;
                    margin: 0 theme-spacing( 2 );
                }

                .american-express-logo {
                    position: relative;
                    top: 6px;
                }

                @include mobile-only {
                    display: flex;
                    align-items: center;

                    span {
                        position: relative !important;
                        top: 1px !important;
                        width: 100% !important;
                        line-height: line-height( 'xxxs' );
                        font-size: font-size( 'xxxxxs' );
                        text-align: right !important;
                    }

                    .american-express-logo {
                        top: 3px !important;
                    }
                }
            }

            @include mobile-only {
                display: flex;
                margin-bottom: theme-spacing( 6 ) !important;
                align-items: center;
                justify-content: space-between;

                .title {
                    line-height: line-height( 'xxxl' );
                    font-size: font-size( 'l' );
                }
            }
        }

        @include mobile-only {
            padding: theme-spacing( 4 ) !important;
            margin-top: theme-spacing( 4 ) !important;

            .line-wrapper {
                flex-direction: column !important;

                .gst-base-form-input {
                    width: 100% !important;
                    flex: 1;
                }

                .gst-base-form-input:first-of-type {
                    margin-right: theme-spacing( 0 ) !important;
                }
            }
        }
    }
</style>
