<template>
    <header class="gst-header-with-count-down d-flex flex-row items-center">
        <router-link :to="{ name: 'home' }" class="flex-grow-1 ">
            <TheLogo />
        </router-link>
        <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="gst-header-with-count-down__title flex-grow-1 text-center">
            {{ $t('title') }}
        </div>
        <div class="gst-header-with-count-down__count-down flex-grow-1 d-flex flex-row align-center justify-end">
            <span class="u-whitespace-nowrap">{{ $t('label' ) }}</span>
            <CountDown
                v-if="dateTimeTill"
                :date-time-till="dateTimeTill"
                format="mm:ss"
                class="gst-count-down mx-1 mx-md-2"
                @finish="$emit( 'finish' )" />
            <BaseTooltip
                bottom
                :text="hotelReservationOnly ? $t('tooltipHotel') : $t('tooltipSeats')"
                class-content="gst-header-with-count-down__tooltip"
                class-activator="d-flex flex-row align-center">
                <BaseIcon symbol-id="icons--help" />
            </BaseTooltip>
            <LanguageSelectField class="ml-6" />
        </div>
    </header>
</template>
<script>
    import CountDown from '@core/shared/components/misc/CountDown.vue';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import LanguageSelectField from '@tenant/app/components/inputs/LanguageSelectField.vue';
    import TheLogo from '@tenant/app/layouts/components/TheLogo.vue';

    export default {
        name: 'HeaderWithCountDown',
        components: {
            CountDown,
            BaseTooltip,
            BaseIcon,
            LanguageSelectField,
            TheLogo
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckout._components.headerWithCountDown'
        },
        props: {
            dateTimeTill: {
                type: Date,
                default: ( ) => { return new Date( ); }
            },
            hotelReservationOnly: {
                type: Boolean,
                default: false
            }
        },
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-header-with-count-down {
        display: flex;
        height: 50px;
        width: 100%;
        padding: theme-spacing( 3 ) theme-spacing( 4 );
        background: linear-gradient( 180deg, theme-color-hex( 'new-quaternary' ) 0%, rgba( theme-color-hex( 'new-quaternary' ), 0 ) 123.33% );
        background-color: rgba( theme-color-hex( 'new-quaternary' ), 1 );
        transition: all 0.5s ease;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        z-index: $z-index-header;

        .gst-header-with-count-down__title {
            @include font-weight('regular');

            line-height: font-size( 'xxxxl' );
            color: theme-color( 'white' );
            font-size: font-size( 'xxxl' );
        }

        .gst-header-with-count-down__count-down {
            @include font-weight( 'large' );

            color: theme-color( 'white' );
            font-size: font-size( 'l' );

            .gst-count-down {
                letter-spacing: 1.1px;
                min-width: 45px;
            }
        }

        a {
            color: theme-color( 'white' );
            font-size: 14px;
        }
    }
</style>

<style lang="scss">
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-header-with-count-down__count-down span.gst-count-down {
        color: theme-color( 'white' ) !important;
    }

    .gst-header-with-count-down__tooltip.v-tooltip__content {
        line-height: font-size( 'l' );
        padding: theme-spacing( 4 );
        background: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' );
        font-size: font-size( 's' );
        max-width: 350px;
        box-shadow: 0 2px 6px rgba( theme-color-hex( 'black' ), 0.25 );
        opacity: 1 !important;
    }
</style>